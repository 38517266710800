import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Header from './Header';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
    content: {
      background: '#F6F6F6',
      paddingTop: 64,
      minHeight: 'calc(100vh-64px)',
      position: 'relative',
      '&  > *': {
        minHeight: 'calc(100vh - 64px)',
      },
    },
  }),
);

type LayoutProps = unknown;

const Layout: FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Header />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

export default Layout;
