import { AppBar, Container, Grid } from '@material-ui/core';
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AgoraProfileButton } from 'ui-components';
import ExampleAvatar from '../../assets/svgs/logo/logoHeader.svg';
import { ReactComponent as Logout } from '../../assets/svgs/logout.svg';
import useTranslations from '../../hooks/useTranslations';
import { setLanguage } from '../../redux/slices/appSlice';
import { logout } from '../../redux/slices/authSlice';
import { RootState } from '../../redux/store';
import HeaderMenu from '../navigation/HeaderMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 64,
      top: 0,
      background: theme.palette.primary.main,
      position: 'fixed',
      boxShadow: 'none',
      zIndex: theme.zIndex.appBar,
    },
    gridContainer: {
      height: '100%',
    },
    logoMenu: {
      marginTop: 48,
      '& .MuiPaper-root': {
        borderRadius: '0 0 8px 8px',
      },
      '& .MuiMenu-list': {
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
    },
    avatar: {
      width: 50,
      height: 50,
      border: '1px solid ' + fade(theme.palette.stroke.dark, 0.3),
      borderRadius: '50%',
    },
    icon: {
      width: 40,
      height: 50,
      cursor: 'pointer',
      marginRight: theme.spacing(8),
    },
    accordionMenu: {
      maxWidth: 400,
      width: 400,
    },
    fullHeight: { height: '100%' },
    progressBarContainer: {
      position: 'absolute',
      top: '100%',
      height: 20,
      width: '100%',
      background: theme.palette.stroke.main,
    },
    progressBarSpan: {
      height: '100%',
      width: 0,
      background: 'linear-gradient(90.16deg, #29E195 -24.95%, #4FC895 101.3%);',
      transition: 'all 0.7s 0.2s ease-out',
    },
    spinner: {
      display: 'absolute',
      width: 20,
    },
    actionButton: {
      textAlign: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      background: theme.palette.primary.main,
      margin: theme.spacing(0, 1),
      padding: theme.spacing(1, 3),
      borderRadius: 8,
      border: '1px solid ' + theme.palette.secondary.main,
      whiteSpace: 'nowrap',
    },
    profile: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: theme.spacing(0, 1),
      height: 40,
      border: '1px solid ' + theme.palette.secondary.dark,
      borderRadius: 8,
      backgroundColor: theme.palette.secondary.main,
    },
    profileImg: {
      width: 32,
      height: 32,
      border: '1px solid ' + theme.palette.primary.main,
      borderRadius: '40%',
      marginRight: theme.spacing(1),
    },
    burger: {
      display: 'flex',
      padding: theme.spacing(0, 1),
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  }),
);

type HeaderProps = unknown;

const Header: FC<HeaderProps> = ({}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslations();
  const mainMenu = true;

  const language = useSelector((state: RootState) => state.app.language);

  return (
    <AppBar className={classes.root} position="static">
      <Container maxWidth={'lg'} className={classes.fullHeight}>
        <Grid
          container
          className={classes.gridContainer}
          alignItems={'center'}
          wrap={'nowrap'}
          justify={'space-between'}
        >
          <Grid item>
            <Grid container alignItems={'center'} wrap={'nowrap'} style={{ flexGrow: 0 }}>
              <img
                src={ExampleAvatar}
                alt={'avatar'}
                className={classes.icon}
                onClick={() => history.push('/suppliers')}
              />
              {mainMenu && <HeaderMenu />}
            </Grid>
          </Grid>
          {/*<Grid item>*/}
          {/*  <Grid container alignItems={'center'} wrap={'nowrap'} style={{ flexGrow: 0 }}>*/}
          {/*    <Box className={classes.profile} >*/}
          {/*      <Box className={classes.burger}>*/}
          {/*        <MenuIcon />*/}
          {/*      </Box>*/}
          {/*      <img src={'/logo192.png'} alt={'profile-image'} className={classes.profileImg} />*/}
          {/*    </Box>*/}
          {/*  </Grid>*/}
          <Grid container item justify={'flex-end'}>
            <Grid item>
              <AgoraProfileButton
                language={language}
                changeLanguage={(lng: string) => {
                  dispatch(setLanguage(lng));
                }}
                buttons={[{ title: t('ui-lib.logout'), Icon: Logout, callback: () => dispatch(logout()) }]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/*{progressBar !== null && (*/}
      {/*  <Box className={classes.progressBarContainer}>*/}
      {/*    <Box className={classes.progressBarSpan} style={{ width: `${progressBar}%` }} />*/}
      {/*  </Box>*/}
      {/*)}*/}
    </AppBar>
  );
};

export default Header;
