import React from 'react';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { typo } from '../../utils/typo';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    zIndex: 100,
    '&.MuiAppBar-root': {
      background: '#ffffff',
    },
    '& .MuiPaper-outlined': {
      boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.25)',
    },
  },
  box: {
    background: theme.palette.secondary.main,
    borderRadius: 0,
  },
  span: {
    width: 30,
    bottom: 0,
    position: 'absolute',
    borderBottom: '2px solid ' + theme.palette.primary.main,
    animation: '$broad 0.2s ease-out',
  },
  '@keyframes broad': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: 30,
    },
  },
  container: {
    padding: '16px 0',
  },
  icon: {
    width: 48,
    height: 48,
    borderRadius: 8,
    backdropFilter: 'blur(13.2719px)',
    '&.MuiAvatar-colorDefault': {
      background: 'linear-gradient(180deg, #50BC8F 0%, #50A6A5 100%)',
      backdropFilter: 'blur(13.2719px)',
    },
    marginRight: '10px',
  },
  details: {
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: 0,
    marginRight: '16px',
  },
  title: {
    whiteSpace: 'nowrap',
  },
  subtitle: {
    color: 'rgba(0, 0, 0, 0.34)',
    whiteSpace: 'nowrap',
  },
  divider: {
    width: '2px',
    height: '40px',
    backgroundColor: '#E3E3E3',
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    '&:hover': {
      background: 'none',
    },
  },
  grid: {
    paddingInline: 40,
  },
}));

type NavMenuProps = {
  breadcrumbs: { title: string; onClick?: () => void }[];
  tabs: { title: string; onClick?: () => void; active?: boolean }[];
  icon?: string;
  title: string;
  subtitle: string;
};

const NavMenu: React.FC<NavMenuProps> = ({ icon, breadcrumbs, tabs, title, subtitle }) => {
  const classes = useStyles();

  const crumbs = () => {
    return (
      <Box display="flex" className={classes.container}>
        <Container maxWidth={'lg'}>
          <Breadcrumbs
            style={(typo(16, 'semi'), { color: 'rgba(0, 0, 0, 0.87)' })}
            separator={<ArrowForwardIosIcon style={{ ...typo(11, 'semi'), margin: '0 10px' }} />}
            aria-label="breadcrumb"
          >
            {breadcrumbs.map((link, index) => {
              return (
                <Box className={classes.link} key={index} onClick={link.onClick}>
                  <Typography> {link.title} </Typography>
                </Box>
              );
            })}
          </Breadcrumbs>
        </Container>
      </Box>
    );
  };

  const getTabs = () => {
    return (
      <Grid container spacing={2} wrap={'nowrap'}>
        {tabs.map((tab, index) => {
          return (
            <Grid item key={index}>
              <Button onClick={tab.onClick} className={classes.btn}>
                <Typography color={'primary'} style={{ ...typo(16, 'semi') }}>
                  {tab.title}
                </Typography>
                {tab.active && <div className={classes.span} />}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box className={classes.appBar}>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.box}>{crumbs()}</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.box} variant={'outlined'}>
            <Box display="flex" className={classes.container}>
              <Container maxWidth={'lg'}>
                <Grid container justify={'flex-start'} alignItems={'center'} wrap={'nowrap'}>
                  <Grid container item alignItems={'center'} wrap={'nowrap'} className={classes.details}>
                    <Avatar alt={'title'} src={icon} className={classes.icon}>
                      <Typography style={typo(14, 'bold')} color={'secondary'}>
                        {title.split(' ').map((i) => {
                          return i.charAt(0).toUpperCase();
                        })}
                      </Typography>
                    </Avatar>
                    <Grid container item alignContent={'center'}>
                      <Grid item xs={12}>
                        <Typography style={typo(24, 'semi')} color={'primary'} className={classes.title}>
                          {title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={typo(16, 'regular')} className={classes.subtitle}>
                          {subtitle}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.grid}>
                    <Divider orientation="vertical" className={classes.divider} />
                  </Grid>
                  <Grid item>
                    <Container maxWidth={'lg'}>{getTabs()}</Container>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NavMenu;
