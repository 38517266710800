/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

let _translations: { [key: string]: any } = {};

const useTranslations = () => {
  const translations: any = useSelector((state: RootState) => state.app.translations);

  return (key: string) => {
    const accessors = key.split('.');

    let value = translations[accessors[0]];
    if (!value) {
      return key;
    }
    for (const accessor of accessors.slice(1)) {
      value = value[accessor];
    }

    return value || key;
  };
};
export const initTranslations = (store: Store) => {
  store.subscribe(() => {
    const translationsFromState = store.getState().app.translations;
    _translations = translationsFromState;
  });
};

export const translate = (key: string) => {
  return _translations[key] || key;
};

export default useTranslations;
