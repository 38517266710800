import React from 'react';

export const typo = (size: number, weight?: 'regular' | 'semi' | 'bold'): React.CSSProperties => {
  let fontWeight = 400;
  if (weight === 'semi') fontWeight = 600;
  else if (weight === 'bold') fontWeight = 700;
  return {
    fontSize: size,
    fontWeight: fontWeight,
  };
};

//<Typography style={typo(16, 'bold')}>Παράδειγμα</Typography>
