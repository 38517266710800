import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceList, Store } from 'types-library';
import {
  createPriceListRequest,
  getPriceListRequest,
  putPriceListRequest,
} from '../../lib/api/http/requests/priceList';
import { getStoresBySupplierRequest } from '../../lib/api/http/requests/supplier';
import { PriceListSliceType } from '../../models/slices/PriceListSliceType';
import { RootState } from '../store';
import { openToast } from './appSlice';

export type RequestParamProps = { skip: number; limit: number; sort?: string; search?: string };

const getStoresBySupplierList = createAsyncThunk(
  'supplier/getStores',
  async (params: { reqParams: RequestParamProps; supplierId: string }, thunkAPI) => {
    try {
      const res = await getStoresBySupplierRequest(params.reqParams, params.supplierId);
      const payload = { stores: res.stores, documentsCount: res.storesCount };
      thunkAPI.dispatch(setStores(payload));
    } catch (error) {
      console.error(error);

      thunkAPI.dispatch(openToast({ text: 'error.dataStores' }));
      throw error;
    }
  },
);

const getMoreStoresBySupplierList = createAsyncThunk(
  'supplier/getMoreStores',
  async (params: { reqParams: RequestParamProps; supplierId: string }, thunkAPI) => {
    try {
      const res = await getStoresBySupplierRequest(params.reqParams, params.supplierId);
      const state = thunkAPI.getState() as RootState;
      const payload = { stores: [...state.priceList.data.stores, ...res.stores], documentsCount: res.storesCount };
      thunkAPI.dispatch(setStores(payload));
    } catch (error) {
      console.error(error);

      thunkAPI.dispatch(openToast({ text: 'error.dataStores' }));
      throw error;
    }
  },
);

const getProductsListBySupplier = createAsyncThunk(
  'supplier/getProducts/list',
  async (params: { reqParams: any; supplierId: string }, thunkAPI) => {
    try {
      const res = await getPriceListRequest(params.reqParams, params.supplierId);
      thunkAPI.dispatch(setProducts({ products: res.documents, documentsCount: res.documentsCount }));
    } catch (error) {
      thunkAPI.dispatch(openToast({ text: 'error.dataProducts' }));
      throw error;
    }
  },
);

const putPriceList = createAsyncThunk(
  'supplier/getProducts/put',
  async (params: { priceListId: string; priceMap: { [key: string]: number } }, thunkAPI) => {
    const priceMap = { ...params.priceMap };
    try {
      for (const [k, v] of Object.entries(params.priceMap)) {
        if (!isNaN(Number(v))) {
          priceMap[k] = Number(v);
        } else {
          thunkAPI.dispatch(openToast({ text: 'error.common', success: false }));
          return;
        }
      }
      const res = await putPriceListRequest({ ...params, priceMap });
      const state = thunkAPI.getState() as RootState;
      const newPriceList = state.priceList.data.productsPriceList.map((p) =>
        p._id === params.priceListId ? { ...p, priceMap: params.priceMap } : p,
      );
      thunkAPI.dispatch(setPriceList({ products: [...newPriceList] }));
      return res;
    } catch (error: any) {
      thunkAPI.dispatch(openToast({ text: 'error.common', success: false }));
      throw Error(error);
    }
  },
);

const createPriceListThunk = createAsyncThunk(
  'supplier/getProducts/put',
  async (params: { store: string; product: string; priceMap: { [key: string]: number } }, thunkAPI) => {
    try {
      const priceMap = { ...params.priceMap };
      for (const [k, v] of Object.entries(params.priceMap)) {
        if (!isNaN(Number(v))) {
          priceMap[k] = Number(v);
        } else {
          thunkAPI.dispatch(openToast({ text: 'error.common', success: false }));
          return;
        }
      }
      const res = await createPriceListRequest({ ...params, priceMap });

      const state = thunkAPI.getState() as RootState;

      thunkAPI.dispatch(setPriceList({ products: [...state.priceList.data.productsPriceList, ...[res]] }));
      return res;
    } catch (error: any) {
      console.log(error);
      thunkAPI.dispatch(openToast({ text: error.response.data.message, success: false }));
      throw Error(error);
    }
  },
);

const initialState: PriceListSliceType = {
  data: {
    stores: [],
    selectedProduct: null,
    productsPriceList: [],
  },
  meta: {
    loadingPricelist: false,
    paginationProducts: 0,
    paginationStores: 0,
  },
};

const priceListSlice = createSlice({
  name: 'priceList',
  initialState: initialState,
  reducers: {
    setStores(state, action: PayloadAction<{ stores: Store[]; documentsCount: number }>) {
      state.data.stores = action.payload.stores;
      state.meta.paginationStores = action.payload.documentsCount;
    },
    setProducts(state, action: PayloadAction<{ products: PriceList[]; documentsCount?: number }>) {
      state.data.productsPriceList = action.payload.products;
      state.meta.paginationProducts = action.payload.documentsCount ? action.payload.documentsCount : 0;
    },
    setPriceList(state, action: PayloadAction<{ products: PriceList[] }>) {
      state.data.productsPriceList = action.payload.products;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductsListBySupplier.fulfilled, (state) => {
      state.meta.loadingPricelist = false;
    });
    builder.addCase(getProductsListBySupplier.pending, (state) => {
      state.meta.loadingPricelist = true;
    });
    builder.addCase(getProductsListBySupplier.rejected, (state) => {
      state.meta.loadingPricelist = false;
    });
  },
});

export const { setStores, setProducts, setPriceList } = priceListSlice.actions;
export {
  getProductsListBySupplier,
  getStoresBySupplierList,
  getMoreStoresBySupplierList,
  // getPriceListProducts,
  createPriceListThunk,
  putPriceList,
};
export default priceListSlice.reducer;
