import { Box } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import NavMenu from '../components/navigation/NavMenu';
import SupplierBlockedStoresPage from '../pages/supplierPages/SupplierBlockedStoresPage';
import SupplierClientsPage from '../pages/supplierPages/SupplierClientsPage';
import SupplierOrdersPage from '../pages/supplierPages/SupplierOrdersPage';
import SupplierProductsList from '../pages/supplierPages/SupplierProductsList';
import SupplierProductsPage from '../pages/supplierPages/SupplierProductsPage';
import SupplierSettingsPage from '../pages/supplierPages/SupplierSettingsPage';
import { getSupplierData } from '../redux/slices/supplierSlice';
import { RootState } from '../redux/store';

type SupplierRoutesProps = unknown;

const SupplierRoutes: FC<SupplierRoutesProps> = ({}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedSupplier = useSelector((state: RootState) => state.supplier.selectedSupplier);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    dispatch(getSupplierData({ supplierId }));
  }, [dispatch, location.pathname]);

  const getValue = (
    objectKey: 'companyDetails' | 'supplierDetails',
    fieldKey: 'name' | 'address' | 'fullName' | 'city',
  ) => {
    if (selectedSupplier) {
      const { [objectKey]: prop = { [fieldKey]: 'N/A' } as any } = selectedSupplier ? selectedSupplier : {};
      return prop[fieldKey] as string;
    } else {
      return '-';
    }
  };

  return (
    <Box>
      <NavMenu
        breadcrumbs={[
          { title: t('suppliers'), onClick: () => history.push('/suppliers') },
          { title: getValue('companyDetails', 'name') },
        ]}
        tabs={[
          {
            title: t('orders'),
            onClick: selectedSupplier ? () => history.push(`/suppliers/${selectedSupplier._id}/orders`) : undefined,
            active: selectedSupplier ? location.pathname === `/suppliers/${selectedSupplier._id}/orders` : false,
          },
          {
            title: t('clientsSupplier'),
            onClick: selectedSupplier ? () => history.push(`/suppliers/${selectedSupplier._id}/clients`) : undefined,
            active: selectedSupplier ? location.pathname === `/suppliers/${selectedSupplier._id}/clients` : false,
          },
          {
            title: t('blocked-clients'),
            onClick: selectedSupplier
              ? () => history.push(`/suppliers/${selectedSupplier._id}/blocked-stores`)
              : undefined,
            active: selectedSupplier
              ? location.pathname === `/suppliers/${selectedSupplier._id}/blocked-stores`
              : false,
          },
          {
            title: t('products'),
            onClick: selectedSupplier ? () => history.push(`/suppliers/${selectedSupplier._id}/products`) : undefined,
            active: selectedSupplier ? location.pathname === `/suppliers/${selectedSupplier._id}/products` : false,
          },
          {
            title: t('price-list'),
            onClick: selectedSupplier
              ? () => history.push(`/suppliers/${selectedSupplier._id}/products/list`)
              : undefined,
            active: selectedSupplier ? location.pathname === `/suppliers/${selectedSupplier._id}/products/list` : false,
          },
          {
            title: t('setting'),
            onClick: selectedSupplier ? () => history.push(`/suppliers/${selectedSupplier._id}/settings`) : undefined,
            active: selectedSupplier ? location.pathname === `/suppliers/${selectedSupplier._id}/settings` : false,
          },
        ]}
        icon={
          selectedSupplier ? (selectedSupplier.supplierLogo ? selectedSupplier.supplierLogo : undefined) : undefined
        }
        title={getValue('supplierDetails', 'fullName')}
        subtitle={
          selectedSupplier ? `${getValue('supplierDetails', 'address')}, ${getValue('supplierDetails', 'city')} ` : '-'
        }
      />
      <Switch>
        <Route exact path={`/suppliers/:id/orders`}>
          <SupplierOrdersPage />
        </Route>
        <Route exact path={`/suppliers/:id/clients`}>
          <SupplierClientsPage />
        </Route>
        <Route exact path={`/suppliers/:id/blocked-stores`}>
          <SupplierBlockedStoresPage />
        </Route>
        <Route exact path={`/suppliers/:id/products`}>
          <SupplierProductsPage />
        </Route>
        <Route exact path={`/suppliers/:id/products/list`}>
          <SupplierProductsList />
        </Route>
        <Route exact path={`/suppliers/:id/settings`}>
          <SupplierSettingsPage />
        </Route>
        <Route>
          <Redirect to={`/suppliers/:id/orders`} />
        </Route>
      </Switch>
    </Box>
  );
};
export default React.memo(SupplierRoutes);
