import React, { FC } from 'react';
import { createStyles, Drawer, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: 'auto',
      flexShrink: 0,
    },
    drawerPaper: {
      ' &.MuiPaper-elevation16': {
        boxShadow: 'none',
        background: '#F1F1F1',
      },
      background: 'none',
      height: '100%',
      width: 'auto',
    },
  }),
);

type DrawerCartProps = {
  open: boolean;
  closeDrawer: () => void;
};

const DrawerCart: FC<DrawerCartProps> = ({ open = false, closeDrawer, children }) => {
  const classes = useStyles();

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    closeDrawer();
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={toggleDrawer()}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {children}
    </Drawer>
  );
};

export default DrawerCart;
