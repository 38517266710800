import { unstable_createMuiStrictModeTheme as createMuiTheme, fade } from '@material-ui/core/styles';
import { PaletteColorOptions } from '@material-ui/core/styles/createPalette';
import { CSSProperties } from 'react';
import { AvertaRegular, AvertaSemibold, AvertaBold } from 'suppliers-app/src/theme/fonts';

declare module '@material-ui/core/styles/createPalette' {
  // eslint-disable-next-line
  interface Palette {
    stroke: Palette['primary'];
  }

  // eslint-disable-next-line
  interface PaletteOptions {
    stroke: PaletteOptions['primary'];
  }
}

const primary: PaletteColorOptions = {
  main: '#000846',
  dark: '#154CBA',
  light: '#DEE4FF',
};

const secondary: PaletteColorOptions = {
  main: '#FFFFFF', // Contrasting to the primary, can be used as background color (pure white)
  dark: '#000000',
  light: '#E5E5E5',
};

const stroke: PaletteColorOptions = {
  main: '#E3E3E3', // Stroke Color & Footer background
  light: '#F6F6F6', // Main grey background
  dark: '#9E9898', // Dark grey
};

const error: PaletteColorOptions = {
  main: '#D75858',
};

const warning: PaletteColorOptions = {
  main: '#F9AB4E',
};

const success: PaletteColorOptions = {
  light: '#A3DBC4',
  main: '#50BC8F',
};

const info: PaletteColorOptions = {
  main: '#1057FC',
  light: '#14C2F7',
  dark: '#5551F4',
};

const theme = createMuiTheme({
  palette: {
    primary,
    secondary,
    stroke,
    info,
    error,
    warning,
    success,
  },
  typography: {
    allVariants: {
      fontFamily: ['Averta'].join(','),
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1115,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [AvertaRegular, AvertaSemibold, AvertaBold] as unknown as CSSProperties,
        body: {
          overflow: 'overlay',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
