import { configureStore, combineReducers } from '@reduxjs/toolkit';
import appReducer from './slices/appSlice';
import layoutReducer from './slices/layoutSlice';
import authReducer from './slices/authSlice';
import supplierReducer from './slices/supplierSlice';
import buyerReducer from './slices/buyerSlice';
import storeReducer from './slices/storeSlice';
import ordersReducer from './slices/orderSlice';
import priceListReducer from './slices/priceListSlice';

const combinedReducer = combineReducers({
  auth: authReducer,
  buyer: buyerReducer,
  supplier: supplierReducer,
  order: ordersReducer,
  store: storeReducer,
  app: appReducer,
  layout: layoutReducer,
  priceList: priceListReducer,
});
// eslint-disable-next-line
const rootReducer = (state: any, action: any) => {
  if (action.type === 'STORE_RESET') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default () => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [],
  });
};
