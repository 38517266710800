import { ORDER_STATUS, PRODUCT_STATUS, PostProductType, Supplier } from 'types-library';
import { RequestParamProps } from '../../../../redux/slices/supplierSlice';
import { deleteRequest, getRequest, postRequest, putRequest } from '../index';
import { ICreateSupplierBody } from '../types/ICreateSupplierBody';
import { Pagination } from '../types/Pagination';

export const approveStore = (supplierId: string, storeId: string) => {
  return deleteRequest(`/agoraServices/admin/suppliers/${supplierId}/blocked-stores/${storeId}`, {
    populate: 'image,blockedStores',
  });
};
const postSupplierRequest = (params: ICreateSupplierBody) => {
  return postRequest(`/agoraServices/admin/suppliers`, { ...params });
};

const getSupplierDataRequest = (id: string) => {
  return getRequest(`agoraServices/admin/suppliers/${id}`, { populate: 'logo,blockedStores' });
};

const getSuppliersRequest = (params: Pagination, pending?: boolean) => {
  return getRequest(`agoraServices/admin/suppliers`, { ...params, populate: 'user,logo,blockedStores', pending });
};

const getOrdersBySupplierRequest = (params: RequestParamProps, supplierId: string, status: ORDER_STATUS | null) => {
  return getRequest(`agoraServices/admin/suppliers/orders`, {
    ...params,
    supplierId,
    status,
    populate: 'supplier,store,store.image,products.image,products.productRef',
  });
};

const getStoresBySupplierRequest = (params: RequestParamProps, supplierId: string) => {
  return getRequest(`agoraServices/admin/suppliers/stores`, {
    ...params,
    supplierId,
    populate: 'buyer.user,image,image',
  });
};

const getProductsBySupplierRequest = (params: RequestParamProps, supplierId: string, status: PRODUCT_STATUS | null) => {
  return getRequest(`agoraServices/admin/suppliers/products`, {
    ...params,
    supplierId,
    status,
    populate: 'category,image',
  });
};

const putSupplierRequest = (supplier: Supplier) => {
  return putRequest(`agoraServices/admin/suppliers/${supplier._id}`, { supplier, populate: 'logo' });
};

const deleteSupplierRequest = (supplierId: string) => {
  return deleteRequest(`agoraServices/admin/suppliers/${supplierId}`);
};

const deleteProductsRequest = (products: string[]) => {
  return deleteRequest(
    `agoraServices/admin/products/many`,
    {
      products,
    },
    true,
  );
};

const updateProductRequest = (product: PostProductType, updatePriceLists: boolean) => {
  return putRequest(`agoraServices/admin/products/${product._id}`, {
    product: product,
    updatePriceLists: updatePriceLists,
  });
};

const getCategoriesRequest = () => {
  return getRequest(`agoraServices/admin/categories`);
};

const getNewCategoriesRequest = (supplierId: string) => {
  return getRequest(`agoraServices/admin/newCategories`, { supplierId });
};

const createProductRequest = (product: PostProductType) => {
  return postRequest(`agoraServices/admin/products`, { product });
};

const getUnits = (supplierId: string) => {
  return getRequest('/agoraServices/admin/units', { supplierId });
};

export {
  createProductRequest,
  deleteProductsRequest,
  deleteSupplierRequest,
  getCategoriesRequest,
  getNewCategoriesRequest,
  getOrdersBySupplierRequest,
  getProductsBySupplierRequest,
  getStoresBySupplierRequest,
  getSupplierDataRequest,
  getSuppliersRequest,
  getUnits,
  postSupplierRequest,
  putSupplierRequest,
  updateProductRequest,
};
