import { Dialog, DialogActions, DialogTitle, Typography, Grid } from '@material-ui/core';
import { AgoraButton } from 'ui-components';
import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { typo } from '../../utils/typo';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

const useStyles = makeStyles(() =>
  createStyles({
    dialogCancel: {
      '& .MuiDialog-paper': {
        borderRadius: '8px',
        padding: '32px',
      },
    },
    titleSetting: {
      ...typo(32, 'semi'),
      textAlign: 'center',
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type DialogCancelType = {
  open: boolean;
  title?: string;
  firstButton?: string;
  secondButton?: string;
  handleClose: () => void;
  yes: () => void;
  no: () => void;
};

const DialogCancel: FC<DialogCancelType> = ({
  handleClose,
  title = 'Ακύρωση παραγγελίας;',
  firstButton = 'ΝΑΙ',
  secondButton = 'OXI',
  open,
  yes,
  no,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      style={{ zIndex: 1305 }}
      open={open}
      onClose={handleClose}
      className={classes.dialogCancel}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography className={classes.titleSetting}>{title}</Typography>
      </DialogTitle>
      <DialogActions style={{ paddingTop: 50 }}>
        <Grid container alignItems={'center'} justify={'center'} spacing={2}>
          <Grid item xs={6}>
            <AgoraButton
              title={firstButton}
              background={'#FFFFF'}
              color={''}
              variant={'outlined'}
              padding={22}
              handleClick={() => yes()}
            />
          </Grid>

          <Grid item xs={6}>
            <AgoraButton title={secondButton} variant={'outlined'} padding={22} handleClick={() => no()} />{' '}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCancel;
