import { initTranslations } from '../hooks/useTranslations';
import { injectStore } from '../lib/api/http/index';
import configureStore from './configureStore';
const store = configureStore();
initTranslations(store);
injectStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
