import AvertaBoldWoff2 from '../../assets/fonts/Averta-Bold.woff2';
import AvertaRegularWoff2 from '../../assets/fonts/Averta-Regular.woff2';
import AvertaSemiboldWoff2 from '../../assets/fonts/Averta-Semibold.woff2';

import InterBoldTtf from '../../assets/fonts/Inter-Bold.ttf';
import InterRegularTtf from '../../assets/fonts/Inter-Regular.ttf';
import InterSemiBoldTtf from '../../assets/fonts/Inter-SemiBold.ttf';

export const AvertaRegular = {
  fontFamily: 'Averta',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url('${AvertaRegularWoff2}') format('woff2')`,
};
export const AvertaSemibold = {
  fontFamily: 'Averta',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `url('${AvertaSemiboldWoff2}') format('woff2')`,
};

export const AvertaBold = {
  fontFamily: 'Averta',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url('${AvertaBoldWoff2}') format('woff2')`,
};

export const InterRegular = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url('${InterRegularTtf}') format('opentype')`,
};
export const InterSemibold = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `url('${InterSemiBoldTtf}') format('opentype')`,
};

export const InterBold = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url('${InterBoldTtf}') format('opentype')`,
};
