import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useTranslations from '../../hooks/useTranslations';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline',
  },
  button: {
    borderRadius: 8,
    padding: theme.spacing(1.2, 2),
    whiteSpace: 'nowrap',
  },
  active: {
    background: 'rgba(255, 255, 255, 0.21)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.21)',
    },
  },
  span: {
    width: 30,
    position: 'absolute',
    bottom: 6,
    borderBottom: '2px solid ' + theme.palette.secondary.main,
    animation: '$broad 0.2s ease-out',
  },
  '@keyframes broad': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: 30,
    },
  },
}));

type HeaderMenuProps = unknown;

const HeaderMenu: React.FC<HeaderMenuProps> = ({}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const t = useTranslations();

  const getButton = (text: string, onClick: () => void, active: boolean) => {
    return (
      <Button onClick={onClick} className={clsx(classes.button, active && classes.active)}>
        <Typography color={'secondary'}>{text}</Typography>
        {active && <div className={classes.span} />}
      </Button>
    );
  };
  return (
    <Box className={classes.root}>
      <Grid container spacing={2} wrap={'nowrap'}>
        {/*<Grid item>{getButton('Επισκόπηση', () => history.push('/preview'), location.pathname === '/preview')}</Grid>*/}
        <Grid item>
          <Grid item>
            {getButton(
              t('suppliers'),
              () => history.push('/suppliers'),
              location.pathname.split('/')[1] === 'suppliers',
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            {getButton(t('buyers'), () => history.push('/buyers'), location.pathname.split('/')[1] === 'buyers')}
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            {getButton(t('orders'), () => history.push('/orders'), location.pathname.split('/')[1] === 'orders')}
          </Grid>
        </Grid>
        {/*<Grid item>*/}
        {/*  <Grid item>{getButton('Μηνύματα', () => history.push('/messages'), location.pathname === '/messages')}</Grid>*/}
        {/*</Grid>*/}
      </Grid>
    </Box>
  );
};

export default HeaderMenu;
