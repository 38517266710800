import React, { FC } from 'react';
import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { typo } from '../../utils/typo';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&.MuiButton-root': {
        borderRadius: '8px',
        paddingInline: 16,
        paddingTop: (props: any) => props.customTop,
        paddingBottom: (props: any) => props.customBottom,
        width: '100%',
        maxWidth: '250px',
        backgroundColor: (props: any) => props.background,
        color: (props: any) => props.color,
        transition: 'all 0.2s',
      },
      '&.MuiButton-contained': {
        '&:hover': {
          opacity: 0.84,
        },
        '&:disabled': {
          opacity: 0.34,
        },
      },
      '&.MuiButton-outlined': {
        border: '1.5px solid #E3E3E3',
        '&:hover': {
          opacity: 0.64,
        },
        '&:disabled': {
          opacity: 0.34,
        },
      },
      '& .MuiButton-startIcon': { marginRight: '16px' },
      '& .MuiButton-endIcon': { marginRight: '16px' },
    },
    hiddenSpan: {
      width: '15px',
      height: '15px',
    },
    buttonProgress: {
      color: (props: any) => props.color,
    },
  }),
);

type SvgButtonProps = {
  title?: string;
  color?: string;
  icon?: any;
  disable?: boolean;
  loading?: boolean;
  customTop?: number;
  customBottom?: number;
  background?: string;
  variant?: 'outlined' | 'contained' | 'text';
  handleClick: () => void;
};

const SvgButton: FC<SvgButtonProps> = ({
  title,
  icon,
  customTop = 11.7,
  customBottom = 13.6,
  background = '#5551F4',
  color = '#FFFFFF',
  disable = false,
  variant = 'contained',
  handleClick,
}) => {
  const classes = useStyles({ background, color, customTop, customBottom });

  const handleButtonClick = () => {
    handleClick();
  };

  return (
    <Button
      fullWidth
      disableRipple
      className={classes.root}
      variant={variant}
      disabled={disable}
      onClick={handleButtonClick}
      startIcon={icon}
    >
      <Typography style={{ ...typo(14, 'semi'), whiteSpace: 'nowrap' }}>{title}</Typography>
    </Button>
  );
};

export default SvgButton;
