import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Buyer } from 'types-library';
import { AgoraButton, AgoraPaginationFilter, AgoraSearchInput } from 'ui-components';
import { ReactComponent as Info } from '../../assets/svgs/info.svg';
import DrawerCart from '../../components/common/DrawerCart';
import DialogCancel from '../../components/dialog/DialogCancel';
import AddBuyerModal from '../../components/modals/AddBuyerModal';
import EditBuyerModal from '../../components/modals/EditBuyerModal';
import CustomTableBody from '../../components/table/CustomTableBody';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import CustomTableHead from '../../components/table/CustomTableHead';
import InnerBuyerStoresTable from '../../components/table/InnerBuyerStoresTable';
import InnerBuyerTable from '../../components/table/InnerBuyerTable';
import TableTabs from '../../components/table/TableTabs';
import { useDebounce } from '../../hooks/useDebounce';
import useTranslations from '../../hooks/useTranslations';
import { Pagination } from '../../lib/api/http/types/Pagination';
import { deleteBuyerThunk, getAllBuyersThunk, setInitialState } from '../../redux/slices/buyerSlice';
import { setLayout } from '../../redux/slices/layoutSlice';
import { deleteStoreThunk, getStoresThunk, setInitialStore } from '../../redux/slices/storeSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 67,
    paddingBottom: 93,
  },
  typography: {
    ...typo(14, 'regular'),
    color: '#9E9898',
  },
  gridInfo: {
    marginTop: 13,
  },
  spaceInfo: {
    marginRight: 4,
  },
  gridTable: {
    marginTop: 36,
  },
}));

type BuyersPageProps = unknown;

const BuyersPage: React.FC<BuyersPageProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslations();

  const buyers = useSelector((state: RootState) => state.buyer.data.buyers);
  const stores = useSelector((state: RootState) => state.store.data.stores);
  const loadingBuyers = useSelector((state: RootState) => state.buyer.meta.loading);
  const loadingStores = useSelector((state: RootState) => state.store.meta.loading);
  const maxBuyerscount = useSelector((state: RootState) => state.buyer.data.pagination.buyers);
  const maxStoresCount = useSelector((state: RootState) => state.store.data.pagination.stores);

  const [tab, setTab] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedOrdersIds, setSelectedOrdersIds] = useState<string[]>([]);
  const [showAddBuyerModal, setShowAddBuyerModal] = useState<boolean>(false);
  const [showEditBuyerModal, setShowEditBuyerModal] = useState<boolean>(false);
  const [toggleNameBuyer, setToggleNameBuyer] = useState<boolean>(false);
  const [toggleNameStore, setToggleNameStore] = useState<boolean>(false);
  const [modalDeleteBuyer, setModalDeleteBuyer] = useState<boolean>(false);
  const [modalDeleteStore, setModalDeleteStore] = useState<boolean>(false);
  const [selectEditBuyer, setSelectedBuyer] = useState<Buyer | null>(null);
  const [buyerId, setIdBuyer] = useState<string>('');
  const [storeId, setIdStore] = useState<string>('');

  const debouncedSearchText = useDebounce(searchInput, 500);

  const tabs = [t('stores'), t('buyers')];

  // Layout Config Effect
  useEffect(() => {
    dispatch(setInitialStore());
    dispatch(setLayout({ header: true, mainMenu: true, footer: true }));
  }, []);

  useEffect(() => {
    if (!showEditBuyerModal) {
      let params: Pagination = {
        skip: page * 10,
        limit: 10,
        sort: '',
        search: debouncedSearchText,
      };
      if (tab === 1) {
        params = { ...params, sort: toggleNameBuyer ? '-name' : 'name' };
        dispatch(getAllBuyersThunk(params));
      } else if (tab === 0) {
        params = { ...params, sort: toggleNameStore ? '-name' : 'name' };
        dispatch(getStoresThunk(params));
      }
    }
  }, [tab, toggleNameBuyer, debouncedSearchText, toggleNameStore, page, showEditBuyerModal]);

  const deleteBuyer = (id: string) => {
    setModalDeleteBuyer(true);
    setIdBuyer(id);
  };

  const editBuyer = (buyer: Buyer) => {
    setSelectedBuyer(buyer);
    setShowEditBuyerModal(true);
  };

  const deleteStore = (id: string) => {
    setModalDeleteStore(true);
    setIdStore(id);
  };

  const showAddBuyer = () => {
    dispatch(setInitialState());
    setSelectedBuyer(null);
    if (showAddBuyerModal) {
      setShowAddBuyerModal(false);
    } else {
      setShowEditBuyerModal(false);
    }
  };

  const getTableBasedOnTable = () => {
    // Buyers
    if (tab === 1) {
      return (
        <InnerBuyerTable
          clients={buyers}
          editBuyer={(buyer) => editBuyer(buyer)}
          deleteBuyer={(id) => deleteBuyer(id)}
          toggleName={toggleNameBuyer}
          setToggleName={(value: boolean) => setToggleNameBuyer(value)}
          loading={loadingBuyers}
        />
      );
    }
    // Stores
    if (tab === 0) {
      return (
        <InnerBuyerStoresTable
          stores={stores}
          toggleName={toggleNameStore}
          setToggleName={(value: boolean) => setToggleNameStore(value)}
          deleteStore={(id: string) => deleteStore(id)}
          loading={loadingStores}
        />
      );
    }
  };

  const getPagination = () => {
    // Buyers
    if (tab === 1) {
      return (
        <AgoraPaginationFilter
          page={page}
          setPage={setPage}
          pageSize={10}
          allCount={maxBuyerscount}
          style={{ marginLeft: 16 }}
        />
      );
    }
    // Stores
    if (tab === 0) {
      return (
        <AgoraPaginationFilter
          page={page}
          setPage={setPage}
          pageSize={10}
          allCount={maxStoresCount}
          style={{ marginLeft: 16 }}
        />
      );
    }
  };

  const handleChangeTab = (num: number) => {
    setSearchInput('');
    setPage(0);
    setTab(num);
  };

  const handleAcceptDelete = () => {
    if (modalDeleteBuyer) {
      setModalDeleteBuyer(false);
      dispatch(deleteBuyerThunk(buyerId));
    } else {
      setModalDeleteStore(false);
      dispatch(deleteStoreThunk(storeId));
    }
  };

  const handleCloseDelete = () => {
    if (modalDeleteBuyer) {
      setModalDeleteBuyer(false);
    } else {
      setModalDeleteStore(false);
    }
  };

  return (
    <Container maxWidth={'lg'}>
      <Grid container alignItems={'center'} justify={'center'} className={classes.root}>
        <Grid container item justify={'space-between'} xs={12} wrap={'nowrap'} alignItems={'center'}>
          <Grid item>
            <Typography style={typo(32, 'bold')}>{t('buyers')}</Typography>
          </Grid>
          <Grid item>
            <AgoraButton title={t('create')} padding={10} handleClick={() => setShowAddBuyerModal(true)} />
          </Grid>
        </Grid>
        <Grid container item justify={'flex-start'} alignItems={'center'} xs={12} className={classes.gridInfo}>
          <Grid item className={classes.spaceInfo}>
            <Info />
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.typography}>{t('viewEdit')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridTable}>
          <CustomTableContainer>
            <CustomTableHead visibleHiddenActions={!!selectedOrdersIds.length}>
              <Grid container justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
                <Grid item>
                  <TableTabs tabs={tabs} selectedTab={tab} setSelectedTab={handleChangeTab} />
                </Grid>
                <Grid item container style={{ flex: 0 }} wrap={'nowrap'} justify={'flex-end'}>
                  <Grid item>
                    <AgoraSearchInput
                      border={'1px solid #E3E3E3'}
                      placeholder={tab === 1 ? t('search.buyer') : t('search.store')}
                      changeSearch={setSearchInput}
                      scroll={false}
                    />
                  </Grid>
                  <Grid item>{getPagination()}</Grid>
                </Grid>
              </Grid>
            </CustomTableHead>
            <CustomTableBody>{getTableBasedOnTable()}</CustomTableBody>
          </CustomTableContainer>
        </Grid>
      </Grid>
      <DrawerCart open={showAddBuyerModal} closeDrawer={showAddBuyer}>
        <AddBuyerModal closeDrawer={showAddBuyer} />
      </DrawerCart>
      {selectEditBuyer && (
        <DrawerCart open={showEditBuyerModal} closeDrawer={showAddBuyer}>
          <EditBuyerModal closeDrawer={showAddBuyer} editBuyer={selectEditBuyer} />
        </DrawerCart>
      )}
      <DialogCancel
        open={modalDeleteBuyer || modalDeleteStore}
        title={t('delete')}
        firstButton={t('accept')}
        secondButton={t('cancel')}
        handleClose={() => handleCloseDelete()}
        yes={() => handleAcceptDelete()}
        no={() => handleCloseDelete()}
      />
    </Container>
  );
};

export default BuyersPage;
