import { Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgoraButton, AgoraPaginationFilter, AgoraSearchInput } from 'ui-components';
import DrawerCart from '../../components/common/DrawerCart';
import DialogCancel from '../../components/dialog/DialogCancel';
import AddSupplierStoreModal from '../../components/modals/AddSupplierStoreModal';
import CustomTableBody from '../../components/table/CustomTableBody';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import CustomTableHead from '../../components/table/CustomTableHead';
import InnerSupplierTable from '../../components/table/InnerSupplierTable';
import TableTabs from '../../components/table/TableTabs';
import { useDebounce } from '../../hooks/useDebounce';
import useTranslations from '../../hooks/useTranslations';
import { Pagination } from '../../lib/api/http/types/Pagination';
import { setLayout } from '../../redux/slices/layoutSlice';
import { deleteSupplierByStoreThunk, getStoreIdThunk, getSuppliersByStoreThunk } from '../../redux/slices/storeSlice';
import { RootState } from '../../redux/store';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 27,
    paddingBottom: 93,
  },
  gridTable: {
    marginTop: 36,
  },
}));

type BuyerSuppliersPageProps = unknown;
const BuyerSuppliersPage: React.FC<BuyerSuppliersPageProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslations();

  const { suppliers, selectedStore } = useSelector((state: RootState) => state.store.data);
  const loading = useSelector((state: RootState) => state.store.meta.loading);
  const maxSuppliersCount = useSelector((state: RootState) => state.store.data.pagination.suppliers);

  const [url, setUrl] = useState<string>('');
  const [tab, setTab] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [showAddSupplierStoreModal, setSupplierStoreModal] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [supplierId, setSupplierId] = useState<string>('');
  const [modalDeleteSupplier, setModalDeleteSupplier] = useState<boolean>(false);

  const debouncedSearchText = useDebounce(searchInput, 500);
  const tabs = [t('suppliers')];

  useEffect(() => {
    const splitPath = window.location.pathname.split('/')[2];
    setUrl(splitPath);
  }, []);

  useEffect(() => {
    if (url) {
      dispatch(getStoreIdThunk(url));
    }
  }, [dispatch, url]);

  // Layout Config Effect
  useEffect(() => {
    dispatch(setLayout({ header: true, mainMenu: true, footer: true }));

    const params: Pagination = {
      skip: page * 10,
      limit: 10,
      search: debouncedSearchText,
    };
    if (selectedStore) {
      dispatch(getSuppliersByStoreThunk(params));
    }
  }, [dispatch, debouncedSearchText, page, selectedStore]);

  const deleteSupplier = (id: string) => {
    setModalDeleteSupplier(true);
    setSupplierId(id);
  };

  const handleCloseDelete = () => {
    setModalDeleteSupplier(false);
  };

  const handleAcceptDelete = () => {
    setModalDeleteSupplier(false);
    dispatch(deleteSupplierByStoreThunk({ supplierId }));
  };

  return (
    <Container maxWidth={'lg'}>
      <Grid container alignItems="center" justify="flex-end" className={classes.root}>
        <Grid item xs={3}>
          <AgoraButton title={t('add.assignmentSup')} padding={10} handleClick={() => setSupplierStoreModal(true)} />
        </Grid>
        <Grid item xs={12} className={classes.gridTable}>
          <CustomTableContainer>
            <CustomTableHead>
              <Grid container justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
                <Grid item>
                  <TableTabs tabs={tabs} selectedTab={tab} setSelectedTab={setTab} />
                </Grid>
                <Grid item container style={{ flex: 0 }} wrap={'nowrap'} justify={'flex-end'}>
                  <Grid item>
                    <AgoraSearchInput
                      border={'1px solid #E3E3E3'}
                      placeholder={t('search.supplier')}
                      changeSearch={setSearchInput}
                      scroll={false}
                    />
                  </Grid>
                  <Grid item>
                    <AgoraPaginationFilter
                      page={page}
                      setPage={(value: number) => setPage(value)}
                      pageSize={10}
                      allCount={maxSuppliersCount}
                      style={{ marginLeft: 16 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CustomTableHead>
            <CustomTableBody>
              <InnerSupplierTable clients={suppliers} deleteClient={(id) => deleteSupplier(id)} loading={loading} />
            </CustomTableBody>
          </CustomTableContainer>
        </Grid>
      </Grid>
      <DrawerCart open={showAddSupplierStoreModal} closeDrawer={() => setSupplierStoreModal(false)}>
        <AddSupplierStoreModal closeDrawer={() => setSupplierStoreModal(false)} />
      </DrawerCart>
      <DialogCancel
        open={modalDeleteSupplier}
        title={t('delete')}
        firstButton={t('accept')}
        secondButton={t('cancel')}
        handleClose={() => handleCloseDelete()}
        yes={() => handleAcceptDelete()}
        no={() => handleCloseDelete()}
      />
    </Container>
  );
};

export default BuyerSuppliersPage;
