import React from 'react';
import ReactDOM from 'react-dom';
import './i18next/i18n';
import App from './App';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import theme from './theme';
import store from './redux/store';
import { Provider } from 'react-redux';
export const dispatch = store.dispatch;

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <App />
      </Provider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
