import {
  Avatar,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Supplier } from 'types-library';
import { AgoraToggleSort } from 'ui-components';
import { ReactComponent as EyeIcon } from '../../assets/svgs/eye.svg';
import { ReactComponent as PenIcon } from '../../assets/svgs/pen.svg';
import { ReactComponent as TrashCanIcon } from '../../assets/svgs/trash-can.svg';
import useTranslations from '../../hooks/useTranslations';
import { openToast } from '../../redux/slices/appSlice';
import { setSelectedSupplier } from '../../redux/slices/supplierSlice';
import { getRandomHue } from '../../utils/getRandomHue';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme) => ({
  root: {
    '& hr': {
      display: 'inline',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#E3E3E3',
    },
  },
  body: {
    '& > *:last-child .MuiTableCell-root': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  row: {
    '& .MuiTableCell-root:not(:last-child)': {
      borderRight: '1px solid #E3E3E3',
    },
  },
  toggleSelect: {
    position: 'relative',
    right: -12,
  },
  selectCell: {
    height: '100%',
    marginRight: theme.spacing(1),
    borderRight: '1px solid #E3E3E3',
  },
  extraSpace: {
    minWidth: 250,
  },
  storeImg: {
    width: 40,
    height: 40,
    maxWidth: 40,
    maxHeight: 40,
    overflow: 'hidden',
    border: '1px solid ' + theme.palette.secondary.dark,
    borderRadius: '40%',
    marginRight: theme.spacing(1),
  },
  defaultIcon: {
    padding: '4px 6px 4px 4px',
  },
  actionButton: {
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    background: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 3),
    borderRadius: 8,
  },
  title: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  img: {
    width: '32px',
    height: '32px',
    borderRadius: '11px',
  },
  icon: {
    width: '32px',
    height: '32px',
    borderRadius: '11px',
    padding: '8px',
    backdropFilter: 'blur(13.2719px)',
    '&.MuiAvatar-colorDefault': {
      background: (props: { hue: number }) =>
        `linear-gradient(0deg, hsl(${props.hue},80%,80%) 0%, hsl(${props.hue + 40},80%,80%) 100%)`,
      backdropFilter: 'blur(13.2719px)',
    },
  },
}));

type InnerSupplierTableProps = {
  clients: Supplier[];
  viewClient?: boolean;
  deleteClient?: (id: string) => void;
  editClient?: (supplier: Supplier) => void;
  loading?: boolean;
};

const InnerSupplierTable: React.FC<InnerSupplierTableProps> = ({
  clients,
  viewClient,
  deleteClient,
  editClient,
  loading,
}) => {
  const [hue] = useState<number>(getRandomHue());
  const classes = useStyles({ hue });
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslations();

  const handleViewButton = (supplier: Supplier) => {
    dispatch(setSelectedSupplier(supplier));
    history.push(`/suppliers/${supplier._id}/orders`);
  };

  const getCellHeaderContent = (params: {
    text: string;
    onClick?: (object: any) => void;
    extra?: boolean;
    state?: null | boolean;
  }) => {
    return (
      <Grid
        container
        wrap={'nowrap'}
        justify={'space-between'}
        className={params.extra ? classes.extraSpace : undefined}
      >
        <Grid item>
          <Typography style={typo(12, 'bold')}>{params.text}</Typography>
        </Grid>
        {params.onClick && (
          <Grid item>
            <AgoraToggleSort onClick={params.onClick} state={params.state} />
          </Grid>
        )}
      </Grid>
    );
  };

  const getTableHead = () => {
    return (
      <TableHead style={{ background: '#F6F6F6' }}>
        <TableRow className={classes.row}>
          <TableCell>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              {getCellHeaderContent({ text: t('supplier-id') })}
            </Grid>
          </TableCell>
          <TableCell>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              {getCellHeaderContent({ text: t('name.supplier') })}
            </Grid>
          </TableCell>
          <TableCell>{getCellHeaderContent({ text: t('contactInfo.address') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: 'Email' })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('contactInfo.phone') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('actions') })}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const getButtons = (supplier: Supplier) => {
    const id = supplier._id ? supplier._id : '';
    const buttons = [];
    if (editClient) {
      buttons.push(
        <Grid item>
          <IconButton onClick={() => editClient(supplier)}>
            <PenIcon />
          </IconButton>
        </Grid>,
      );
    }
    if (deleteClient) {
      buttons.push(
        <Grid item>
          <IconButton onClick={() => deleteClient(id)}>
            <TrashCanIcon />
          </IconButton>
        </Grid>,
      );
    }
    if (viewClient) {
      buttons.push(
        <Grid item>
          <IconButton onClick={() => handleViewButton(supplier)}>
            <EyeIcon />
          </IconButton>
        </Grid>,
      );
    }
    return (
      <Grid container alignItems="center" justify="center">
        {buttons.map((button, index) => (
          <React.Fragment key={index}>
            {button}
            {index !== buttons.length - 1 && (
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  const getRow = (client: Supplier) => {
    const { name = 'Ν/Α', address = 'Ν/Α' } = client ? (client.companyDetails ? client.companyDetails : {}) : {};
    const { phone = '-' } = client ? (client.supplierDetails ? client.supplierDetails : {}) : {};
    const email = client.user ? client.user.email : '-';
    const image = client.user ? client.supplierLogo : '';

    return (
      <TableRow className={classes.row}>
        <TableCell>
          <Typography
            style={{ ...typo(12, 'bold'), cursor: 'pointer' }}
            onClick={() => {
              navigator.clipboard.writeText(client._id || '');
              dispatch(openToast({ text: 'Copied' }));
            }}
          >
            {client._id}
          </Typography>
        </TableCell>
        <TableCell padding={'none'}>
          <Grid container alignItems="center" wrap={'nowrap'} style={{ paddingLeft: 12 }} spacing={1}>
            <Grid item>
              <Avatar
                className={client.supplierLogo ? classes.img : classes.icon}
                src={client.supplierLogo ? client.supplierLogo : undefined}
                alt={client.companyDetails ? client.companyDetails.name : ''}
              >
                <Typography style={typo(12, 'bold')} color={'secondary'}>
                  {client.companyDetails?.name
                    .split(' ')
                    .slice(0, 2)
                    .map((i) => {
                      return i.charAt(0).toUpperCase();
                    })}
                </Typography>
              </Avatar>
            </Grid>
            <Grid item>
              <Typography style={{ ...typo(14, 'semi'), maxWidth: 160 }}>{name}</Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{address}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{email}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>+30 {phone}</Typography>
        </TableCell>
        <TableCell>{getButtons(client)}</TableCell>
      </TableRow>
    );
  };

  const getTableBody = () => {
    return (
      <TableBody className={classes.body}>
        {!loading ? (
          clients.length === 0 ? (
            <TableRow>
              <TableCell padding={'none'} colSpan={5}>
                <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                  <Typography style={{ ...typo(16, 'regular'), color: '#999' }}>{t('msg.data')}</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            clients.map((client, index) => <React.Fragment key={index}>{getRow(client)}</React.Fragment>)
          )
        ) : (
          <TableRow>
            <TableCell padding={'none'} colSpan={5}>
              <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                <CircularProgress style={{ width: 24, height: 24, color: '#999' }} />
              </Grid>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <Table className={classes.root}>
      {getTableHead()}
      {getTableBody()}
    </Table>
  );
};

export default InnerSupplierTable;
