import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from '.';
import Toast from './components/structure/Toast';
import { getTranslationsThunk } from './redux/slices/appSlice';
import { RootState } from './redux/store';
import Routes from './Routes/Routes';
import initialize from './utils/initialize';

initialize();

function App() {
  const language = useSelector((state: RootState) => state.app.language);
  useEffect(() => {
    dispatch(getTranslationsThunk());
  }, [language]);

  return (
    <React.Suspense fallback={null}>
      <Box>
        <Routes />
        <Toast />
      </Box>
    </React.Suspense>
  );
}

export default App;
