import { Store } from 'types-library';
import { deleteRequest, getRequest, putRequest } from '../index';
import { Pagination } from '../types/Pagination';
import { PaginationOrder } from '../types/PaginationOrder';

export interface IGetStorePricelistsRequestParams {
  storeId?: string;
  order?: string;
  skip?: number;
  limit?: number;
  sort?: string;
  category?: string;
  supplierId?: string;
  status?: string;
  deal?: boolean;
  searchText?: string;
  favourites?: boolean;
}

const getStoresRequest = (params: Pagination) => {
  return getRequest('/agoraServices/admin/buyers/stores?populate=buyer.user,image', { ...params });
};

const getStoreByIdRequest = (storeId: string) => {
  return getRequest(`agoraServices/admin/stores/${storeId}?populate=image`);
};

const deleteStoreRequest = (storeId: string) => {
  return deleteRequest(`agoraServices/admin/stores/${storeId}`);
};

const putStoreRequest = (params: Store) => {
  return putRequest(`agoraServices/admin/stores/${params._id}`, { store: params });
};

const getSuppliersByStoreId = (params: Pagination) => {
  return getRequest(`/agoraServices/admin/buyers/supplierByStore?populate=user,logo`, { ...params });
};

const deleteSupplierByStoreId = (params: { storeId: string; supplierId: string }) => {
  return deleteRequest(`/agoraServices/admin/stores/${params.storeId}/supplier/${params.supplierId}`);
};

const getOrdersByStoreId = (params: PaginationOrder) => {
  return getRequest(
    `/agoraServices/admin/buyers/ordersByStore?populate=supplier,store,store.image,products.image,products.productRef`,
    {
      ...params,
    },
  );
};

const putSuppliersStoreId = (params: { ids: string[]; storeId: string }) => {
  return putRequest(`/agoraServices/admin/stores/${params.storeId}/suppliers`, {
    store: params.storeId,
    suppliers: params.ids,
  });
};

const getStorePricelists = (params: IGetStorePricelistsRequestParams) => {
  return getRequest(`/agoraServices/admin/stores/${params.storeId}/pricelists`, { ...params });
};

export {
  deleteStoreRequest,
  deleteSupplierByStoreId,
  getOrdersByStoreId,
  getStoreByIdRequest,
  getStorePricelists,
  getStoresRequest,
  getSuppliersByStoreId,
  putStoreRequest,
  putSuppliersStoreId,
};
