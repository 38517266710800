import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountry } from 'types-library';
import { getTranslationsRequest } from '../../lib/api/http/requests/app';
import { getCountries } from '../../lib/api/http/requests/utils';
import { RootState } from '../store';

type appSliceType = {
  countries: ICountry[];
  translations: { [key: string]: any };
  language: string;
  appLoading: boolean;
  countriesLoading: boolean;
  toast: {
    open: boolean;
    text: string;
    success: boolean;
  };
};

const initialState: appSliceType = {
  countries: [],
  translations: {},
  language: 'GR',
  appLoading: false,
  countriesLoading: false,
  toast: {
    open: false,
    text: '',
    success: false,
  },
};

export const getTranslationsThunk = createAsyncThunk('app/get-translations', async (params, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const translations = await getTranslationsRequest(state.app.language);

  return translations;
});

export const getCountriesThunk = createAsyncThunk('app/getCountries', async (params: any, thunkAPI): Promise<
  ICountry[]
> => {
  try {
    return await getCountries(params.searchText);
  } catch (error: any) {
    thunkAPI.dispatch(openToast({ text: 'error-generic' }));
    throw Error(error);
  }
});

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
    openToast(state, action: PayloadAction<{ text: string; success?: boolean }>) {
      state.toast.open = true;
      state.toast.text = action.payload.text;
      state.toast.success = !!action.payload.success;
    },
    closeToast(state) {
      state.toast.open = false;
      state.toast.text = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountriesThunk.pending, (state) => {
      state.countriesLoading = true;
    });
    builder.addCase(getCountriesThunk.fulfilled, (state, action) => {
      state.countries = action.payload;
      state.countriesLoading = false;
    });
    builder.addCase(getCountriesThunk.rejected, (state) => {
      state.countriesLoading = false;
    });

    builder.addCase(getTranslationsThunk.pending, (state: appSliceType) => {
      state.appLoading = true;
    });
    builder.addCase(getTranslationsThunk.fulfilled, (state, action: PayloadAction<{ [key: string]: unknown }>) => {
      state.translations = action.payload;
      state.appLoading = false;
    });
  },
});

export const { openToast, closeToast, setLanguage } = appSlice.actions;
export default appSlice.reducer;
