import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../components/structure/Layout';
import SuppliersPage from '../pages/supplierPages/SuppliersPage';
import BuyersPage from '../pages/buyerPages/BuyersPage';
import BuyerRoutes from './BuyerRoutes';
import SupplierRoutes from './SupplierRoutes';
import OrdersPage from '../pages/OrdersPage';

type ProtectedRoutesProps = unknown;

const ProtectedRoutes: FC<ProtectedRoutesProps> = ({}) => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/suppliers">
          <SuppliersPage />
        </Route>
        <Route path="/suppliers/:id">
          <SupplierRoutes />
        </Route>
        <Route exact path="/buyers">
          <BuyersPage />
        </Route>
        <Route path="/buyers/:id">
          <BuyerRoutes />
        </Route>
        <Route path={'/orders'}>
          <OrdersPage />
        </Route>
        <Route>
          <Redirect to={'/suppliers'} />
        </Route>
      </Switch>
    </Layout>
  );
};

export default ProtectedRoutes;
