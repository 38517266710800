/* eslint-disable react/react-in-jsx-scope */
import { Box } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import NavMenu from '../components/navigation/NavMenu';
import BuyerOrdersPage from '../pages/buyerPages/BuyerOrdersPage';
import BuyerSettingsPage from '../pages/buyerPages/BuyerSettingsPage';
import BuyerSuppliersPage from '../pages/buyerPages/BuyerSuppliersPage';
import BuyersFavouritesPage from '../pages/buyerPages/BuyersFavouritesPage';
import { RootState } from '../redux/store';

type BuyerRoutesProps = unknown;

const BuyerRoutes: FC<BuyerRoutesProps> = ({}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const selectedStore = useSelector((state: RootState) => state.store.data.selectedStore);

  return (
    <Box>
      <NavMenu
        breadcrumbs={[
          { title: t('stores'), onClick: () => history.push('/buyers') },
          { title: selectedStore ? selectedStore.name : '' },
        ]}
        tabs={[
          {
            title: t('suppliers'),
            onClick: selectedStore ? () => history.push(`/buyers/${selectedStore._id}/suppliers`) : undefined,
            active: selectedStore ? location.pathname === `/buyers/${selectedStore._id}/suppliers` : false,
          },
          {
            title: t('orders'),
            onClick: selectedStore ? () => history.push(`/buyers/${selectedStore._id}/orders`) : undefined,
            active: selectedStore ? location.pathname === `/buyers/${selectedStore._id}/orders` : false,
          },
          {
            title: t('favourites'),
            onClick: selectedStore ? () => history.push(`/buyers/${selectedStore._id}/favourites`) : undefined,
            active: selectedStore ? location.pathname === `/buyers/${selectedStore._id}/favourites` : false,
          },
          {
            title: t('setting'),
            onClick: selectedStore ? () => history.push(`/buyers/${selectedStore._id}/settings`) : undefined,
            active: selectedStore ? location.pathname === `/buyers/${selectedStore._id}/settings` : false,
          },
        ]}
        title={selectedStore ? selectedStore.name : ''}
        subtitle={selectedStore ? `${selectedStore.address}, ${selectedStore.area} ` : ''}
        icon={selectedStore ? (selectedStore.storeImage ? selectedStore.image : undefined) : undefined}
      />
      <Switch>
        <Route exact path={`/buyers/:id/suppliers`}>
          <BuyerSuppliersPage />
        </Route>
        <Route exact path={`/buyers/:id/orders`}>
          <BuyerOrdersPage />
        </Route>
        <Route exact path={`/buyers/:id/settings`}>
          <BuyerSettingsPage />
        </Route>
        <Route exact path={`/buyers/:id/favourites`}>
          <BuyersFavouritesPage />
        </Route>
        <Route>
          <Redirect to={`/buyers/:id/suppliers`} />
        </Route>
      </Switch>
    </Box>
  );
};

export default BuyerRoutes;
