import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import mime from 'mime';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PostProductType, Product, PRODUCT_STATUS } from 'types-library';
import { AgoraPaginationFilter, AgoraProductInnerTable, AgoraSearchInput } from 'ui-components';
import { ReactComponent as Add } from '../../assets/svgs/Add.svg';
import SvgButton from '../../components/common/SvgButton';
import AddProductDrawer from '../../components/product/AddProductDrawer';
import CustomTableBody from '../../components/table/CustomTableBody';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import CustomTableHead from '../../components/table/CustomTableHead';
import TableTabs from '../../components/table/TableTabs';
import { useDebounce } from '../../hooks/useDebounce';
import useTranslations from '../../hooks/useTranslations';
import { IUploadFileParams } from '../../lib/api/http/requests/file';
import {
  createFileAndProductThunk,
  createProduct,
  deleteProducts,
  getCategories,
  getNewCategoriesThunk,
  getProductsBySupplier,
  getStoresBySupplier,
  getUnitsThunk,
  setClearState,
  setProgressBar,
  setSelectedProduct,
  updateProduct,
} from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  gridTable: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const RESULTS_PER_PAGE = 10;

type SupplierProductsPageProps = unknown;

const SupplierProductsPage: React.FC<SupplierProductsPageProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslations();

  const { products, clearState, progressBar, selectedSupplier, selectedProduct, frontPage } = useSelector(
    (state: RootState) => state.supplier,
  );
  const loading = useSelector((state: RootState) => state.supplier.loading);
  const maxProductsCount = useSelector((state: RootState) => state.supplier.pagination.products);
  const categories = useSelector((state: RootState) => state.supplier.superDrawer.categories);
  const newCategories = useSelector((state: RootState) => state.supplier.newCategories);
  const units = useSelector((state: RootState) => state.supplier.units);

  const [tab, setTab] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [refetchProducts, setRefetchProducts] = useState<number>(0);
  const [toggleName, setToggleName] = useState<boolean | null>(null);
  const [toggleCode, setToggleCode] = useState<boolean | null>(null);
  const [selectedProductsIds, setSelectedProductsIds] = useState<string[]>([]);
  const [editProductDrawer, setEditProductDrawer] = useState<boolean>(false);
  const [addProductDrawer, setAddProductDrawer] = useState<boolean>(false);
  const clients = useSelector((state: RootState) => state.supplier.stores);
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearchText = useDebounce(searchInput, 500);

  const tabs = [
    t('productsStatusTable.all'),
    t('productsStatusTable.published'),
    t('productsStatusTable.unpublished'),
    t('productsStatusTable.out'),
  ];

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    dispatch(getNewCategoriesThunk({ supplierId }));
  }, [dispatch]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    if (supplierId) {
      dispatch(getUnitsThunk({ supplierId: supplierId || '' }));
    }
  }, [dispatch]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    const sort = '';

    dispatch(
      getStoresBySupplier({
        reqParams: { skip: 0, limit: 9999, sort, search: '' },
        supplierId,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    let status: PRODUCT_STATUS | null = null;
    switch (tab) {
      case 0:
        status = null;
        break;
      case 1:
        status = PRODUCT_STATUS.ACTIVE;
        break;
      case 2:
        status = PRODUCT_STATUS.INACTIVE;
        break;
      case 3:
        status = PRODUCT_STATUS.OUT_OF_STOCK;
        break;
    }
    let sort = '';
    if (toggleName !== null) {
      sort += toggleName ? 'name' : '-name';
    }
    if (toggleCode !== null) {
      sort += toggleCode ? '-productCode' : 'productCode';
    }
    dispatch(
      getProductsBySupplier({
        reqParams: { skip: page * RESULTS_PER_PAGE, limit: RESULTS_PER_PAGE, sort, search: debouncedSearchText },
        status,
        supplierId,
      }),
    );
  }, [
    dispatch,
    page,
    tab,
    debouncedSearchText,
    toggleName,
    toggleCode,
    refetchProducts,
    editProductDrawer,
    addProductDrawer,
  ]);

  const resetToggles = () => {
    setToggleName(null);
    setToggleCode(null);
  };

  const handleToggleName = (value: boolean | null) => {
    resetToggles();
    setToggleName(value);
  };

  const handleToggleCode = (value: boolean | null) => {
    resetToggles();
    setToggleCode(value);
  };

  const openAddProductDrawer = () => {
    setAddProductDrawer(true);
  };

  const openEditProductDrawer = (product: Product) => {
    dispatch(setSelectedProduct(product));
    setEditProductDrawer(true);
  };

  const closeAddProductDrawer = () => {
    setAddProductDrawer(false);
    dispatch(setClearState(true));
  };

  const closeEditProductDrawer = () => {
    setEditProductDrawer(false);
    dispatch(setClearState(true));
  };

  const handleDeleteProducts = () => {
    dispatch(
      deleteProducts({ productsIds: selectedProductsIds, callback: () => setRefetchProducts(refetchProducts + 1) }),
    );
  };

  const handleCreateProduct = (product: PostProductType, checkbox: boolean, file?: IUploadFileParams) => {
    if (file) {
      const params = { file: file, product: product, updatePriceLists: checkbox };
      dispatch(createFileAndProductThunk(params));
    } else {
      dispatch(createProduct(product));
    }
  };

  const handleUpdateProduct = (product: PostProductType, checkbox: boolean, file?: IUploadFileParams) => {
    if (file) {
      const uploadFile = {
        folder: 'products',
        name:
          product.name.replace(/ /g, '') +
          '.' +
          mime.getExtension(file.data.split(',')[0]?.split(';')[0].split(':')[1]),
        data: file.data,
      };
      const params = { file: uploadFile, product: product, updatePriceLists: checkbox };
      dispatch(createFileAndProductThunk(params));
    } else {
      dispatch(updateProduct({ product, updatePriceLists: checkbox }));
    }
  };

  const progress = (val: number) => {
    dispatch(setProgressBar(val));
  };

  const clear = (val: boolean) => {
    dispatch(setClearState(val));
  };

  const showProducts = () => {
    return (
      <Grid container className={classes.root}>
        <Grid container alignItems={'center'}>
          <Grid item xs={9} md={3}>
            <Typography style={typo(18, 'semi')}>{t('tableProducts')}</Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <SvgButton
              handleClick={openAddProductDrawer}
              title={t('add.product')}
              icon={<Add />}
              variant={'outlined'}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridTable}>
          <CustomTableContainer>
            <CustomTableHead
              selectedTab={tab}
              visibleHiddenActions={!!selectedProductsIds.length}
              actionDeleteSelected={handleDeleteProducts}
            >
              <Grid container justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
                <Grid item>
                  <TableTabs tabs={tabs} selectedTab={tab} setSelectedTab={setTab} />
                </Grid>
                <Grid item container style={{ flex: 0 }} wrap={'nowrap'} justify={'flex-end'}>
                  <Grid item>
                    <AgoraSearchInput
                      border={'1px solid #E3E3E3'}
                      placeholder={t('search.products')}
                      changeSearch={setSearchInput}
                      scroll={false}
                    />
                  </Grid>
                  <Grid item>
                    <AgoraPaginationFilter
                      page={page}
                      setPage={setPage}
                      pageSize={RESULTS_PER_PAGE}
                      allCount={maxProductsCount}
                      style={{ marginLeft: 16 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CustomTableHead>
            <CustomTableBody>
              <AgoraProductInnerTable
                categories={categories}
                units={units}
                products={products}
                selectedOrdersIds={selectedProductsIds}
                setSelectedOrdersIds={setSelectedProductsIds}
                loading={loading}
                toggleName={toggleName}
                toggleCode={toggleCode}
                setToggleName={handleToggleName}
                setToggleCode={handleToggleCode}
                showProduct={(p: Product) => openEditProductDrawer(p)}
              />
            </CustomTableBody>
          </CustomTableContainer>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth={'lg'} className={classes.root}>
      <Grid container>
        {showProducts()}
        {categories && selectedSupplier ? (
          <AddProductDrawer mode="create" open={addProductDrawer} closeDrawer={() => closeAddProductDrawer()} />
        ) : null}

        {selectedProduct && selectedSupplier && categories ? (
          <AddProductDrawer
            mode="edit"
            open={editProductDrawer}
            product={selectedProduct}
            closeDrawer={() => closeEditProductDrawer()}
          />
        ) : null}
      </Grid>
    </Container>
  );
};

export default SupplierProductsPage;
