import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileType, Order, ORDER_STATUS, OrderProduct, PriceList, Store } from 'types-library';
import { postFile } from '../../lib/api/http/requests/file';
import {
  deliverOrderRequest,
  discountOrderRequest,
  getProductPriceListRequest,
  reviewOrderRequest,
} from '../../lib/api/http/requests/order';
import {
  deleteStoreRequest,
  deleteSupplierByStoreId,
  getOrdersByStoreId,
  getStoreByIdRequest,
  getStorePricelists,
  getStoresRequest,
  getSuppliersByStoreId,
  IGetStorePricelistsRequestParams,
  putStoreRequest,
  putSuppliersStoreId,
} from '../../lib/api/http/requests/store';
import { Pagination } from '../../lib/api/http/types/Pagination';
import { PaginationOrder } from '../../lib/api/http/types/PaginationOrder';
import { StoreSliceType } from '../../models/slices/StoreSliceType';
import { RootState } from '../store';
import { openToast } from './appSlice';

const initialState: StoreSliceType = {
  data: {
    suppliers: [],
    stores: [],
    orders: [],
    pricelists: {},
    selectedStore: null,
    selectedOrder: null,
    pagination: {
      stores: 0,
      suppliers: 0,
      orders: 0,
    },
    supplierId: '',
    deleteStoreId: '',
    storeId: '',
  },
  meta: {
    loading: false,
    error: '',
    success: '',
  },
};

export const createImageThunk = createAsyncThunk('file/createImage', async (params: FileType, thunkAPI) => {
  try {
    const state: RootState = thunkAPI.getState() as RootState;
    const editStore = state.store.data.selectedStore;
    const file = await postFile(params);
    if (file.url && editStore) {
      const store = { ...editStore, storeImage: file.url };
      return await putStoreRequest(store);
    }
  } catch (error) {
    thunkAPI.dispatch(openToast({ text: 'error.dataStores' }));
    throw error;
  }
});

export const getStoresThunk = createAsyncThunk('store/getStores', async (params: Pagination, thunkAPI) => {
  try {
    return await getStoresRequest(params);
  } catch (error) {
    thunkAPI.dispatch(openToast({ text: 'error.dataStores' }));
    throw error;
  }
});

export const getStoreIdThunk = createAsyncThunk('store/getStore/id', async (params: string, thunkAPI) => {
  try {
    return await getStoreByIdRequest(params);
  } catch (error) {
    thunkAPI.dispatch(openToast({ text: 'error.dataStores' }));
    throw error;
  }
});

export const getSuppliersByStoreThunk = createAsyncThunk('store/getSuppliers', async (params: Pagination, thunkAPI) => {
  try {
    const state: RootState = thunkAPI.getState() as RootState;
    const storeId = state.store.data.selectedStore?._id;
    thunkAPI.dispatch(setLoading(true));
    const pagination = { ...params, storeId: storeId };
    if (storeId) {
      return await getSuppliersByStoreId(pagination);
    }
  } catch (error: any) {
    if (error && error.response && error.response.data && error.response.data.details) {
      const details = error.response.data.details;
      if (details.includes('Suppliers array is missing from store')) {
        thunkAPI.dispatch(openToast({ text: 'error.suppliersArray', success: false }));
      }
    } else {
      thunkAPI.dispatch(openToast({ text: 'error.dataSuppliers', success: false }));
    }
    throw error;
  }
});

export const deleteSupplierByStoreThunk = createAsyncThunk(
  'store/deleteSupplier',
  async (params: { supplierId: string; storeId?: string }, thunkAPI) => {
    try {
      const state: RootState = thunkAPI.getState() as RootState;
      const storeId = params.storeId ? params.storeId : state.store.data.selectedStore?._id;
      if (storeId && params.supplierId) {
        const param = { storeId: storeId, supplierId: params.supplierId };
        await deleteSupplierByStoreId(param);
        return params.supplierId;
      }
    } catch (error) {
      thunkAPI.dispatch(openToast({ text: 'error.common' }));
      throw error;
    }
  },
);

export const getOrdersByStoreThunk = createAsyncThunk('store/getOrders', async (params: PaginationOrder, thunkAPI) => {
  try {
    const state: RootState = thunkAPI.getState() as RootState;
    const storeId = state.store.data.selectedStore?._id;
    const pagination = { ...params, storeId: storeId };
    thunkAPI.dispatch(setLoading(true));
    if (storeId) {
      return await getOrdersByStoreId(pagination);
    }
  } catch (error) {
    thunkAPI.dispatch(openToast({ text: 'error.dataOrders' }));
    throw error;
  }
});

export const putStoreThunk = createAsyncThunk('store/putStore', async (params: Store, thunkAPI) => {
  try {
    const store = await putStoreRequest(params);
    if (store) {
      thunkAPI.dispatch(getStoreIdThunk(store._id));
    }
  } catch (error) {
    console.error(error);
    thunkAPI.dispatch(openToast({ text: 'error.putStore' }));
    throw error;
  }
});

export const deleteStoreThunk = createAsyncThunk('store/deleteStore', async (params: string, thunkAPI) => {
  try {
    thunkAPI.dispatch(setStoreId(params));
    return await deleteStoreRequest(params);
  } catch (error: any) {
    if (error && error.response && error.response.data && error.response.data.details) {
      const details = error.response.data.details;
      if (details.includes('Delete of default store is not allowed!')) {
        thunkAPI.dispatch(openToast({ text: 'error.allowed', success: false }));
      }
    } else {
      thunkAPI.dispatch(openToast({ text: 'error.deleteStore', success: false }));
    }
    throw error;
  }
});

export const putSupplierStore = createAsyncThunk('supplier/putSupplierS', async (params: string[], thunkAPI) => {
  try {
    const state: RootState = thunkAPI.getState() as RootState;
    const storeId = state.store.data.selectedStore?._id;
    if (storeId) {
      const data = { storeId: storeId, ids: params };
      const res = await putSuppliersStoreId(data);
      if (res) {
        const params: Pagination = { skip: 0, limit: 10, search: '', sort: '' };
        thunkAPI.dispatch(getSuppliersByStoreThunk(params));
        thunkAPI.dispatch(getStoreIdThunk(storeId));
      }
    }
  } catch (error) {
    console.error(error);
    thunkAPI.dispatch(openToast({ text: 'error.putSupplier', success: false }));
    throw error;
  }
});

export const getProductPrices = createAsyncThunk(
  'store/getProductPrices',
  async (params: { productId: string; storeId: string; trueProductId: string }, thunkAPI) => {
    try {
      const res = await getProductPriceListRequest(params);
      const state: RootState = thunkAPI.getState() as RootState;
      const selectedOrder = state.store.data.selectedOrder;
      if (selectedOrder && res.productPriceList.pricePerUnit) {
        const updatedProducts: OrderProduct[] = selectedOrder.products.map((p) => {
          if (p._id === params.trueProductId) {
            return { ...p, price: res.productPriceList.pricePerUnit[p.unit], _loading: false };
          } else {
            return p;
          }
        });
        thunkAPI.dispatch(setSelectedOrder({ ...selectedOrder, products: [...updatedProducts] }));
      }
    } catch (error) {
      const state: RootState = thunkAPI.getState() as RootState;
      const selectedOrder = state.store.data.selectedOrder;
      if (selectedOrder) {
        const updatedProducts = selectedOrder.products.map((p) => {
          if (p._id === params.trueProductId) {
            return { ...p, _loading: false };
          } else {
            return p;
          }
        });
        thunkAPI.dispatch(setSelectedOrder({ ...selectedOrder, products: updatedProducts }));
      }
      console.error(error);
      thunkAPI.dispatch(openToast({ text: `Failed to retrieve product price lists` }));
      throw error;
    }
  },
);

export const acceptOrder = createAsyncThunk(
  'store/acceptOrder',
  async (params: { orderId: string; productPrices: Array<{ productId: string; price: number }> }, thunkAPI) => {
    try {
      await reviewOrderRequest(
        { accepted: true, rejectedProducts: [], productPrices: params.productPrices },
        params.orderId,
      );
      const state: RootState = thunkAPI.getState() as RootState;
      const selectedOrder = state.store.data.selectedOrder;
      if (selectedOrder) {
        let cost = 0;
        selectedOrder.products.forEach((p) => {
          if (p.price && p.quantity) cost += p.price * p.quantity;
        });

        if (selectedOrder.autoAccept) {
          thunkAPI.dispatch(setSelectedOrder({ ...selectedOrder, status: ORDER_STATUS.ACCEPTED, cost }));
        } else {
          thunkAPI.dispatch(setSelectedOrder({ ...selectedOrder, status: ORDER_STATUS.PENDING_ACCEPTANCE, cost }));
        }
      }
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: `error.acceptOrder` }));
      throw error;
    }
  },
);

export const declineOrder = createAsyncThunk(
  'store/declineOrder',
  async (params: { orderId: string; callback?: () => void }, thunkAPI) => {
    try {
      await reviewOrderRequest({ accepted: false, rejectedProducts: [], productPrices: [] }, params.orderId);
      const state: RootState = thunkAPI.getState() as RootState;
      const selectedOrder = state.store.data.selectedOrder;
      if (selectedOrder) thunkAPI.dispatch(setSelectedOrder({ ...selectedOrder, status: ORDER_STATUS.REJECTED }));
      if (params.callback) params.callback();
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: `error.declineOrder` }));
      throw error;
    }
  },
);

export const deliverOrder = createAsyncThunk(
  'store/deliverOrder',
  async (params: { orderId: string; callback?: () => void }, thunkAPI) => {
    try {
      await deliverOrderRequest(params.orderId);
      const state: RootState = thunkAPI.getState() as RootState;
      const selectedOrder = state.store.data.selectedOrder;
      if (selectedOrder) thunkAPI.dispatch(setSelectedOrder({ ...selectedOrder, status: ORDER_STATUS.DELIVERED }));
      if (params.callback) params.callback();
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: `error.declineOrder` }));
      throw error;
    }
  },
);

export const partialAcceptOrder = createAsyncThunk(
  'store/partialAcceptOrder',
  async (
    params: {
      orderId: string;
      rejectedProducts: string[];
      modifiedProducts: { productId: string; quantity: number }[];
      callback?: () => void;
    },
    thunkAPI,
  ) => {
    try {
      const res = await reviewOrderRequest(
        {
          accepted: true,
          rejectedProducts: params.rejectedProducts,
          modifiedProducts: params.modifiedProducts,
          productPrices: [],
        },
        params.orderId,
      );
      if (params.callback) params.callback();
      if (res && res.orders) {
        const order = res.orders[0];
        thunkAPI.dispatch(setSelectedOrder(order));
      }
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: `error.partialOrder` }));
      throw error;
    }
  },
);

export const discountOrder = createAsyncThunk(
  'store/discountOrder',
  async (params: { orderId: string; discount: number; callback?: () => void }, thunkAPI) => {
    try {
      await discountOrderRequest(params.orderId, params.discount);
      const state: RootState = thunkAPI.getState() as RootState;
      const selectedOrder = state.store.data.selectedOrder;
      if (selectedOrder) thunkAPI.dispatch(setSelectedOrder({ ...selectedOrder, discount: params.discount }));
      if (params.callback) params.callback();
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: `error.discoutnOrder` }));
      throw error;
    }
  },
);

export const getStorePricelistsThunk = createAsyncThunk(
  'store/getPricelists',
  async (params: IGetStorePricelistsRequestParams, thunkAPI) => {
    try {
      const pricelists = await getStorePricelists(params);
      return pricelists;
    } catch (error: any) {
      thunkAPI.dispatch(openToast({ text: 'error-generic' }));
      throw Error(error);
    }
  },
);

const storeSlice = createSlice({
  name: 'store',
  initialState: initialState,
  reducers: {
    setSelectedStore(state, action: PayloadAction<Store>) {
      state.data.selectedStore = action.payload;
    },
    setInitialStore(state) {
      state.data.selectedStore = initialState.data.selectedStore;
      state.data.orders = initialState.data.orders;
      state.data.suppliers = initialState.data.suppliers;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.meta.loading = action.payload;
    },
    setOrders(state, action: PayloadAction<{ orders: Order[]; documentsCount?: number }>) {
      state.data.orders = action.payload.orders;
      state.data.pagination.orders = action.payload.documentsCount ? action.payload.documentsCount : 0;
    },
    setStoreId(state, action: PayloadAction<string>) {
      state.data.deleteStoreId = action.payload;
    },
    setSelectedOrder(state, action: PayloadAction<Order>) {
      state.data.selectedOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStoresThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(getStoresThunk.fulfilled, (state, action) => {
      state.data.stores = action.payload.stores;
      state.data.pagination.stores = action.payload.storesCount;
      state.meta.loading = false;
    });
    builder.addCase(getStoresThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(putSupplierStore.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(putSupplierStore.fulfilled, (state) => {
      state.meta.loading = false;
    });
    builder.addCase(putSupplierStore.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(deleteStoreThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(deleteStoreThunk.fulfilled, (state) => {
      const newStores = [...state.data.stores];
      const deleteId = state.data.deleteStoreId;
      newStores.splice(
        newStores.findIndex((store) => store._id === deleteId),
        1,
      );
      state.data.stores = newStores;
      state.meta.loading = false;
    });
    builder.addCase(deleteStoreThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(getSuppliersByStoreThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(getSuppliersByStoreThunk.fulfilled, (state, action) => {
      state.data.suppliers = action.payload.suppliers;
      state.data.pagination.suppliers = action.payload.suppliersCount;
      state.meta.loading = false;
    });
    builder.addCase(getSuppliersByStoreThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(deleteSupplierByStoreThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(deleteSupplierByStoreThunk.fulfilled, (state, action) => {
      const newSuppliers = [...state.data.suppliers];
      const deleteId = action.payload;
      newSuppliers.splice(
        newSuppliers.findIndex((supplier) => supplier._id === deleteId),
        1,
      );
      state.data.suppliers = newSuppliers;
      state.meta.loading = false;
    });
    builder.addCase(deleteSupplierByStoreThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(getStoreIdThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(getStoreIdThunk.fulfilled, (state, action) => {
      state.data.selectedStore = action.payload;
      state.meta.loading = false;
    });
    builder.addCase(getStoreIdThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });

    builder.addCase(getOrdersByStoreThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(getOrdersByStoreThunk.fulfilled, (state, action) => {
      state.data.orders = action.payload.orders;
      state.data.pagination.orders = action.payload.ordersCount;
      state.meta.loading = false;
    });
    builder.addCase(getOrdersByStoreThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(createImageThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(createImageThunk.fulfilled, (state) => {
      state.meta.loading = false;
    });
    builder.addCase(createImageThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });

    builder.addCase(getStorePricelistsThunk.pending, (state) => {
      state.meta.loading = true;
      return;
    });
    builder.addCase(getStorePricelistsThunk.fulfilled, (state, action: PayloadAction<PriceList[]>) => {
      state.data.pricelists = action.payload.reduce((t, c, i) => {
        return {
          ...t,
          [c.productRef._id]: c,
        };
      }, {});
      state.meta.loading = false;
    });
    builder.addCase(getStorePricelistsThunk.rejected, (state) => {
      state.meta.loading = false;
      return;
    });
  },
});

export const { setInitialStore, setOrders, setLoading, setSelectedStore, setStoreId, setSelectedOrder } =
  storeSlice.actions;
export default storeSlice.reducer;
