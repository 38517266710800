import { getRequest } from '../index';

const getProductsRequest = (params: {
  storeId: string;
  searchText?: string;
  skip: number;
  limit: number;
  sort: string;
}) => {
  return getRequest(`/agoraServices/admin/getAvailableProducts`, {
    ...params,
    populate: 'supplierId,image',
  });
};

export { getProductsRequest };
