import { AgoraButton, AgoraInputContainer, AgoraInputFragment } from 'ui-components';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import { RootState } from '../redux/store';
import { login, setStatusCode } from '../redux/slices/authSlice';
import { useTranslation } from 'react-i18next';

type LoginFormProps = unknown;
const LoginForm: React.FC<LoginFormProps> = ({}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(4),
      marginTop: theme.spacing(20),
    },
    loginForm: {
      marginTop: theme.spacing(4),
    },
    copyRight: {
      fontWeight: 600,
      textAlign: 'right',
      marginTop: theme.spacing(4),
    },
    loginHeader: {
      fontWeight: 600,
      fontSize: '40px',
    },
    forgotPass: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(8),
      textAlign: 'right',
      cursor: 'pointer',
      userSelect: 'none',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.info.main,
      },
    },

    errorIcon: {
      color: theme.palette.secondary.main,
      '& path': {
        stroke: theme.palette.error.main,
      },
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loading = useSelector((state: RootState) => state.auth.meta.loading);
  const statusCode = useSelector((state: RootState) => state.auth.meta.statusCode);
  const [uiError, setUiError] = useState<string>('');
  const [apiError, setApiError] = useState<string>('');
  const [loginEmail, setLoginEmail] = useState<string>('');
  const [loginEmailError, setLoginEmailError] = useState<boolean>(false);
  const [loginPass, setLoginPass] = useState<string>('');
  const [loginPassError, setLoginPassError] = useState<boolean>(false);

  const handleLogin = () => {
    resetErrors();
    if (loginEmail.length === 0 || loginPass.length === 0) {
      setUiError(t('error.fields'));
      if (loginEmail.length === 0) setLoginEmailError(true);
      if (loginPass.length === 0) setLoginPassError(true);
      return;
    } else {
      dispatch(login({ email: loginEmail, password: loginPass }));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  useEffect(() => {
    if (statusCode === 200) {
      setApiError('');
    } else if (statusCode === 401) {
      setLoginEmailError(true);
      setLoginPassError(true);
      setApiError(t('error.login'));
    } else {
      setApiError(`Error ${statusCode}`);
    }
  }, [statusCode, t]);

  const resetErrors = () => {
    dispatch(setStatusCode(200));
    setApiError('');
    setUiError('');
    setLoginEmailError(false);
    setLoginPassError(false);
  };

  const getErrorText = () => {
    // API errors have priority over any UI errors
    return apiError.length > 0 ? apiError : uiError;
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.loginHeader}>{t('msg.connectMng')}</Typography>
      <div className={classes.loginForm} onKeyDown={handleKeyPress}>
        <AgoraInputContainer>
          <AgoraInputFragment
            description={t('addEmail')}
            type={'text'}
            value={loginEmail}
            setValue={setLoginEmail}
            error={loginEmailError}
          />
          <AgoraInputFragment
            description={t('password')}
            type={'password'}
            value={loginPass}
            setValue={setLoginPass}
            error={loginPassError}
          />
        </AgoraInputContainer>
      </div>
      <Box mt={4}>
        <AgoraButton handleClick={handleLogin} title={t('login')} loading={loading} />
      </Box>
      <Typography className={classes.copyRight}>© 2021 Orderit. All Rights Reserved</Typography>
      {getErrorText().length !== 0 && (
        <Box mt={2} ml={2}>
          <Grid container wrap={'nowrap'} alignItems={'center'}>
            <InfoIcon className={classes.errorIcon} />
            <Typography color={'error'}>{getErrorText()}</Typography>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default LoginForm;
