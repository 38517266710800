import { Box, Container, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgoraButton, AgoraPaginationFilter, AgoraSearchInput } from 'ui-components';
import { ReactComponent as CloseIcon } from '../../assets/svgs/x.svg';
import { useDebounce } from '../../hooks/useDebounce';
import useTranslations from '../../hooks/useTranslations';
import { Pagination } from '../../lib/api/http/types/Pagination';
import { putSupplierStore } from '../../redux/slices/storeSlice';
import { getSuppliers } from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';
import BoxContainer from '../common/BoxContainer';
import SelectedBuyer from '../common/SelectedBuyer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#F1F1F1',
    width: '100%',
    maxWidth: 1200,
  },
  appBar: { paddingTop: 20, paddingRight: 16 },
  buttonClose: {
    padding: 0,
  },
  container: {
    '&.MuiContainer-root': {
      paddingRight: 16,
      marginLeft: 80,
      paddingTop: 25,
    },
  },
}));

type AddSupplierStoreModalProps = {
  closeDrawer: () => void;
};

const AddSupplierStoreModal: FC<AddSupplierStoreModalProps> = ({ closeDrawer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslations();

  const selectedStore = useSelector((state: RootState) => state.store.data.selectedStore);
  const suppliersCount = useSelector((state: RootState) => state.supplier.pagination.suppliers);
  const suppliers = useSelector((state: RootState) => state.supplier.suppliers);
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const debouncedSearchText = useDebounce(searchInput, 500);

  useEffect(() => {
    const pagination: Pagination = {
      skip: page * 10,
      limit: 10,
      sort: '',
      search: debouncedSearchText,
    };
    const params = { params: pagination, peding: true };
    dispatch(getSuppliers(params));
  }, [dispatch, debouncedSearchText, page]);

  useEffect(() => {
    if (suppliers && selectedStore && selectedStore.suppliers) {
      const suppliersIds = selectedStore.suppliers as string[];
      let ids: string[] = [...suppliersIds];
      if (selectedItemsIds.length > 0) {
        ids = [...selectedItemsIds];
      }
      setSelectedItemsIds(ids);
    }
  }, [suppliers, selectedStore]);

  const putSupplier = () => {
    if (selectedStore && selectedItemsIds.length >= 0) {
      dispatch(putSupplierStore(selectedItemsIds));
    } else {
      closeDrawer();
    }
    closeDrawer();
  };

  const AppBar = () => {
    return (
      <Grid container alignItems={'center'} justify={'flex-start'} className={classes.appBar}>
        <Grid container item xs={1} alignItems={'center'} justify={'center'}>
          <IconButton className={classes.buttonClose} onClick={() => closeDrawer()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container item xs={6} justify={'flex-start'} alignItems={'center'}>
          <Typography style={typo(32, 'semi')}>{t('add.assignmentSup')}</Typography>
        </Grid>
        <Grid container item xs={5} justify={'flex-end'} alignItems={'center'} style={{ paddingRight: 8 }}>
          <Grid item>
            <AgoraButton
              title={t('cancel')}
              padding={14}
              variant={'outlined'}
              handleClick={closeDrawer}
              background={'#FFFFFF'}
              color={''}
            />
          </Grid>
          <Grid item style={{ padding: '0 16px' }}>
            <AgoraButton title={t('save')} padding={14} handleClick={() => putSupplier()} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const toggleSelectItem = (id: string | undefined) => {
    if (id)
      if (!selectedItemsIds.includes(id)) {
        setSelectedItemsIds([...selectedItemsIds, id]);
      } else {
        setSelectedItemsIds(selectedItemsIds.filter((_id) => _id !== id));
      }
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid container item xs={12} style={{ marginTop: 16 }}>
          {AppBar()}
        </Grid>
        <Container maxWidth={'md'} className={classes.container}>
          <Grid container item justify={'space-between'} spacing={2} xs={12}>
            <Grid item>
              <Grid container item alignItems="center" justify="center">
                <BoxContainer title={t('add.assignmentSup')}>
                  <Grid container spacing={2}>
                    <Grid item container wrap={'nowrap'} justify={'flex-end'}>
                      <Grid item>
                        <AgoraSearchInput
                          border={'1px solid #E3E3E3'}
                          placeholder={t('search.supplier')}
                          changeSearch={setSearchInput}
                          scroll={false}
                        />
                      </Grid>
                      <Grid item>
                        <AgoraPaginationFilter
                          page={page}
                          setPage={(value: number) => setPage(value)}
                          pageSize={10}
                          allCount={suppliersCount}
                          style={{ marginLeft: 16 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} style={{ maxWidth: 800 }}>
                      {suppliers.length === 0 ? (
                        <Grid container item justify="center">
                          <Typography>{t('msg.dataSup')}</Typography>
                        </Grid>
                      ) : (
                        suppliers.map((supplier, index) => (
                          <SelectedBuyer
                            key={index}
                            supplier={supplier}
                            selected={selectedItemsIds.includes(supplier._id ? supplier._id : '')}
                            setSelected={() => toggleSelectItem(supplier._id)}
                          />
                        ))
                      )}
                    </Grid>
                  </Grid>
                </BoxContainer>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
};

export default AddSupplierStoreModal;
