import { postRequest } from '../index';

export interface IUploadFileParams {
  name: string;
  data: string;
  folder: string;
}
const postFile = (params: IUploadFileParams) => {
  return postRequest('/agoraServices/admin/storage/upload', { ...params });
};

export { postFile };
