import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import AuthRoutes from './AuthRoutes';
import ProtectedRoutes from './ProtectedRoutes';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const Routes = () => {
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  return (
    <Router>
      <Switch>{accessToken ? <ProtectedRoutes /> : <AuthRoutes />}</Switch>
    </Router>
  );
};

export default Routes;
