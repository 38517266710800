import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginRequest } from '../../lib/api/http/requests/authentication';
import { openToast } from './appSlice';
import Cookies from 'js-cookie';
type Admin = {
  _id: string;
  user: string;
  name: string;
};
type authSliceType = {
  admin?: Admin;
  accessToken: string | null;
  refreshToken: string | null;
  meta: {
    loading: boolean;
    statusCode: number;
  };
};

const initialState: authSliceType = {
  admin: undefined,
  accessToken: null,
  refreshToken: null,
  meta: {
    loading: false,
    statusCode: 200,
  },
};

const login = createAsyncThunk('auth/login', async (params: { email: string; password: string }, thunkAPI) => {
  try {
    const res = await loginRequest(params.email, params.password);
    const { token } = res;
    Cookies.set('adminToken', token);
    // Cookies.set('adminRefresh', refreshToken);
    return { token };
  } catch (error) {
    if (error.response.status === 401) {
      thunkAPI.dispatch(setStatusCode(401));
    } else {
      thunkAPI.dispatch(openToast({ text: 'error.common', success: false }));
    }
    throw error;
  }
});

const logout = createAsyncThunk('auth/logout', async (params, thunkAPI) => {
  try {
    // await logoutRequest();
  } catch (error) {
    thunkAPI.dispatch(openToast({ text: 'error.logout' }));
    throw error;
  } finally {
    thunkAPI.dispatch(setToken(null));
    Cookies.remove('adminToken');
    thunkAPI.dispatch({ type: 'STORE_RESET' });
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      state.accessToken = action.payload;
    },
    setAdmin(state, action: PayloadAction<Admin>) {
      state.admin = action.payload;
    },
    setRefreshToken(state, action: PayloadAction<string | null>) {
      state.refreshToken = action.payload;
    },
    setStatusCode(state, action: PayloadAction<number>) {
      state.meta.statusCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.accessToken = action.payload.token;
      // state.refreshToken = action.payload.refreshToken;
      state.meta.loading = false;
      state.meta.statusCode = 200;
    });
    builder.addCase(login.pending, (state) => {
      state.meta.loading = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.meta.loading = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.meta.loading = false;
    });
    builder.addCase(logout.pending, (state) => {
      state.meta.loading = true;
    });
    builder.addCase(logout.rejected, (state) => {
      state.meta.loading = false;
    });
  },
});

export const { setAdmin, setToken, setRefreshToken, setStatusCode } = authSlice.actions;
export { login, logout };
export default authSlice.reducer;
