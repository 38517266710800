import { postRequest, getRequest } from '../index';

const loginRequest = (username: string, password: string) => {
  return postRequest('login', { username, password });
};

// const getAdminRequest = () => {
//   return getRequest('/cms/function/getAdminData');
// };

const getUserRequest = () => {
  return getRequest('authentication/user');
};

const logoutRequest = () => {
  return postRequest('authentication/logout');
};

const forgotPassRequest = (email: string) => {
  return postRequest('authentication/forgot-password', { email });
};

const changePassRequest = (body: { password: string; passwordResetToken: string }) => {
  return postRequest('authentication/reset-password', body);
};

const refreshTokenRequest = (body: { refreshToken: string }) => {
  return postRequest('authentication/renew', body);
};

export { loginRequest, getUserRequest, logoutRequest, forgotPassRequest, changePassRequest, refreshTokenRequest };
