import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { typo } from '../../utils/typo';

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFFFFF',
    border: '1px solid #E3E3E3',
    boxSizing: 'border-box',
    borderRadius: ' 8px',
    padding: 32,
  },
}));

type BoxContainerProp = {
  title: string;
};

const BoxContainer: FC<BoxContainerProp> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container alignItems={'center'}>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <Typography style={typo(18, 'semi')}>{title}</Typography>
        </Grid>
        {children}
      </Grid>
    </Box>
  );
};

export default BoxContainer;
