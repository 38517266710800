import { getRequest, postRequest, putRequest } from '../index';

type ReviewOrderBody = {
  accepted: boolean;
  rejectedProducts: string[];
  productPrices: Array<{ productId: string; price: number }>;
  modifiedProducts?: Array<{ productId: string; quantity: number }>;
};

const getAllOrdersRequest = (params: {
  skip: number;
  limit: number;
  searchText: string;
  status: string | null;
  sort: string;
}) => {
  return getRequest(`/agoraServices/admin/getOrders`, {
    ...params,
    populate: 'store,populate,store.image,products.image,supplier,products.productRef',
  });
};

const getProductPriceListRequest = (params: { productId: string; storeId: string }) => {
  return getRequest('/agoraServices/admin/productPriceList', params);
};

const reviewOrderRequest = (body: ReviewOrderBody, id: string) => {
  return postRequest(`/agoraServices/admin/reviewOrder/${id}`, body);
};

const deliverOrderRequest = (orderId: string) => {
  return putRequest(`/agoraServices/admin/deliverOrder/${orderId}`);
};

const discountOrderRequest = (orderId: string, discount: number) => {
  return putRequest(`/agoraServices/admin/discountOrder/${orderId}`, { discount });
};

export {
  deliverOrderRequest,
  discountOrderRequest,
  getAllOrdersRequest,
  getProductPriceListRequest,
  reviewOrderRequest,
};
