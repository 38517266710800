import { Box, Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FileType } from 'types-library';
import { AgoraProfileAvatar, AgoraSettingsFieldItem } from 'ui-components';
import DialogCancel from '../../components/dialog/DialogCancel';
import useTranslations from '../../hooks/useTranslations';
import { openToast } from '../../redux/slices/appSlice';
import { createImageThunk, deleteStoreThunk, getStoreIdThunk, putStoreThunk } from '../../redux/slices/storeSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 24,
  },
  box: {
    marginTop: 24,
    marginBottom: 16,
  },
  warningBox: {
    color: theme.palette.error.light,
  },
  editOptions: {
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  editIcon: {
    fontSize: '1.3em',
  },
}));

type BuyerSettingsPageProps = unknown;
const BuyerSettingsPage: React.FC<BuyerSettingsPageProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslations();

  const store = useSelector((state: RootState) => state.store.data.selectedStore);

  const [url, setUrl] = useState<string>('');
  const [uploadImage, setUploadImage] = useState<string>('');
  const [modalDeleteStore, setModalDeleteStore] = useState<boolean>(false);
  const initialState = {
    name: 'N/A',
    address: 'N/A',
    area: 'N/A',
    postCode: 'N/A',
    phone: '-',
    taxReferenceNumber: '-',
  };
  const [{ name, address, area, postCode, phone, taxReferenceNumber }, setStore] = useState(initialState);

  useEffect(() => {
    if (store) {
      reset();
    }
  }, [store]);

  useEffect(() => {
    const splitPath = window.location.pathname.split('/')[2];
    setUrl(splitPath);
  }, []);

  //setStore
  useEffect(() => {
    if (url) {
      dispatch(getStoreIdThunk(url));
    }
  }, [dispatch, url]);

  const reset = () => {
    if (store && store.storeImage) {
      setUploadImage(store.storeImage);
    }
    setStore({
      name: store ? store.name : initialState.name,
      address: store ? store.address : initialState.address,
      area: store ? store.area : initialState.area,
      postCode: store ? store.postCode : initialState.postCode,
      phone: store ? store.phone : initialState.phone,
      taxReferenceNumber: store ? store.taxReferenceNumber : initialState.taxReferenceNumber,
    });
  };

  const handleSave = (attributeName: string, inputFieldValue: string) => {
    if (store) {
      const params = { ...store, [attributeName]: inputFieldValue };
      dispatch(putStoreThunk(params)); // store mustn't be null
    }
  };

  const deleteStore = () => {
    setModalDeleteStore(true);
  };

  const handleAcceptDelete = () => {
    if (store && store._id) {
      setModalDeleteStore(false);
      dispatch(deleteStoreThunk(store._id));
      history.push(`/buyers/`);
    } else {
      dispatch(openToast({ text: 'Something went wrong' }));
    }
  };

  const handleCloseDelete = () => {
    setModalDeleteStore(false);
  };

  const handleImageChange = (img: string) => {
    const imageString = img.split(',')[1];
    const file: FileType = { folder: '', name: name, container: 'supplier-image', data: imageString, isPublic: true };
    dispatch(createImageThunk(file));
  };

  return (
    <Container maxWidth={'md'} className={classes.container}>
      <Grid container className={classes.box} justify={'space-between'}>
        <AgoraProfileAvatar
          title={t('settings.logo')}
          imgSrc={uploadImage}
          changeImage={(value: string) => handleImageChange(value)}
          variant={'circular'}
        />
      </Grid>
      <AgoraSettingsFieldItem
        fieldName={t('settings.name')}
        value={name}
        setValue={(value: string) => setStore((prevState) => ({ ...prevState, name: value }))}
        handleSave={() => handleSave('name', name)}
        handleCancel={reset}
      />

      <Divider />
      <AgoraSettingsFieldItem
        fieldName={t('settings.address')}
        value={address}
        setValue={(value: string) => setStore((prevState) => ({ ...prevState, address: value }))}
        handleSave={() => handleSave('address', address)}
        handleCancel={reset}
      />
      <Divider />
      <AgoraSettingsFieldItem
        fieldName={t('settings.city')}
        value={area}
        setValue={(value: string) => setStore((prevState) => ({ ...prevState, area: value }))}
        handleSave={() => handleSave('area', area)}
        handleCancel={reset}
      />
      <Divider />
      <AgoraSettingsFieldItem
        fieldName={t('settings.postCode')}
        value={postCode}
        setValue={(value: string) => setStore((prevState) => ({ ...prevState, postCode: value }))}
        handleSave={() => handleSave('postCode', postCode)}
        handleCancel={reset}
      />
      <Divider />
      <AgoraSettingsFieldItem
        fieldName={t('settings.phone')}
        value={phone}
        setValue={(value: string) => setStore((prevState) => ({ ...prevState, phone: value }))}
        handleSave={() => handleSave('phone', phone)}
        handleCancel={reset}
      />

      <Divider />
      <AgoraSettingsFieldItem
        fieldName={t('contactInfo.afm')}
        value={taxReferenceNumber}
        setValue={(value: string) => setStore((prevState) => ({ ...prevState, taxReferenceNumber: value }))}
        handleSave={() => handleSave('taxReferenceNumber', taxReferenceNumber)}
        handleCancel={reset}
      />
      <Divider />
      <Grid container className={classes.box} justify={'space-between'} alignItems="center">
        <Box className={classes.warningBox}>
          <Typography style={typo(16, 'bold')}>{t('actions')}</Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Box className={clsx(classes.editOptions, classes.warningBox)} onClick={() => deleteStore()}>
            <CloseIcon className={clsx(classes.editIcon)} />
            {t('settings.deleteStore')}
          </Box>
        </Box>
      </Grid>
      <DialogCancel
        open={modalDeleteStore}
        title={t('delete')}
        firstButton={t('accept')}
        secondButton={t('cancel')}
        handleClose={() => handleCloseDelete()}
        yes={() => handleAcceptDelete()}
        no={() => handleCloseDelete()}
      />
    </Container>
  );
};

export default BuyerSettingsPage;
