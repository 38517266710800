import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import Cookies from 'js-cookie';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(
    new LanguageDetector(null, {
      order: ['cookie'],
      lookupCookie: 'i18next',
    }),
  )

  // pass the i18n instance to react-_i18next.
  .use(initReactI18next)
  // init _i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: Cookies.get('i18next') || 'GB',

    backend: {
      loadPath: `${process.env['REACT_APP_API_V1_URL']}/translations/platform/admin-app/{{lng}}`,
    },
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
      // not needed for react as it escapes by default
      format: (value) => {
        if (value instanceof Date) {
          return moment(new Date(value)).format('DD/MM/YYYY');
        }
        return value;
      },
    },
  });

export default i18n;
