import { Buyer } from 'types-library';
import { deleteRequest, getRequest, postRequest, putRequest } from '../index';
import { ICreateBuyerBody } from '../types/ICreateBuyerBody';
import { Pagination } from '../types/Pagination';

const postBuyerRequest = (params: ICreateBuyerBody) => {
  return postRequest(`/agoraServices/admin/buyers`, { ...params });
};

const getAllBuyersRequest = (params: Pagination) => {
  return getRequest(`/agoraServices/admin/buyers`, { ...params, populate: 'user,defaultStore,stores' });
};

const getCountStoresRequest = (id: string) => {
  return getRequest(`/agoraServices/admin/buyers/count/`, { buyerId: id });
};

const getBuyerRequest = (id: string) => {
  return getRequest(`/agoraServices/admin/buyers/${id}`, { populate: 'user' });
};

const putBuyerRequest = (id: string, buyer: Buyer) => {
  return putRequest(`/agoraServices/admin/buyers/${id}`, { buyer: buyer });
};

const deleteBuyerRequest = (id: string) => {
  return deleteRequest(`/agoraServices/admin/buyers/${id}`);
};

export {
  deleteBuyerRequest,
  getAllBuyersRequest,
  getBuyerRequest,
  getCountStoresRequest,
  postBuyerRequest,
  putBuyerRequest,
};
