import React from 'react';
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme) => ({
  tabs: {
    minHeight: 'unset',
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.info.main,
      height: 4,
      borderRadius: 8,
      bottom: 0,
    },
  },
  tab: {
    ...typo(12, 'bold'),
    opacity: 1 + ' !important',
    textAlign: 'center',
    textTransform: 'none',
    padding: theme.spacing(0, 0, 1, 0),
    marginRight: theme.spacing(3),
    minHeight: 'unset',
    minWidth: 'unset',
  },
}));

type TableTabsProps = {
  tabs: string[];
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
};

const TableTabs: React.FC<TableTabsProps> = ({ tabs, selectedTab, setSelectedTab }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<Record<string, never>>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        scrollButtons={'off'}
        variant={'scrollable'}
        value={selectedTab}
        onChange={handleChange}
        className={classes.tabs}
      >
        {tabs.map((tab, index) => (
          <Tab label={tab} key={index} className={classes.tab} />
        ))}
      </Tabs>
    </Box>
  );
};

export default TableTabs;
