import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'types-library';
import { AgoraToggleSort } from 'ui-components';
import DefaultImage from '../../assets/svgs/default-product.svg';
import { ReactComponent as TrashCanIcon } from '../../assets/svgs/trash-can.svg';
import useTranslations from '../../hooks/useTranslations';
import { deleteStoreBySupplierThunk } from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';
import DialogCancel from '../dialog/DialogCancel';

const useStyles = makeStyles((theme) => ({
  root: {
    '& hr': {
      display: 'inline',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#E3E3E3',
    },
  },
  body: {
    '& > *:last-child .MuiTableCell-root': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  row: {
    '& .MuiTableCell-root:not(:last-child)': {
      borderRight: '1px solid #E3E3E3',
    },
  },
  toggleSelect: {
    position: 'relative',
    right: -12,
  },
  selectCell: {
    height: '100%',
    marginRight: theme.spacing(1),
    borderRight: '1px solid #E3E3E3',
  },
  extraSpace: {
    minWidth: 250,
  },
  storeImg: {
    width: 40,
    height: 40,
    maxWidth: 40,
    maxHeight: 40,
    overflow: 'hidden',
    border: '1px solid ' + theme.palette.secondary.dark,
    borderRadius: '40%',
    marginRight: theme.spacing(1),
  },
  defaultIcon: {
    padding: '4px 6px 4px 4px',
  },
  actionButton: {
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    background: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 3),
    borderRadius: 8,
  },
  btn: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1, 2),
    borderRadius: 8,
    userSelect: 'none',
    cursor: 'pointer',
  },
}));

type StoresInnerTableProps = {
  clients: Store[];
  viewClient?: () => void;
  sortName?: boolean | null;
  setSortName?: (value: boolean | null) => void;
  loading?: boolean;
};

const StoresInnerTable: React.FC<StoresInnerTableProps> = ({ clients, sortName, setSortName, viewClient, loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslations();

  const [openUnlinkClientModal, setOpenUnlinkClientModal] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<Store | null>(null);
  const selectedSupplier = useSelector((state: RootState) => state.supplier.selectedSupplier);

  const onClickUnlinkClient = (store: Store) => {
    setSelectedClient(store);
    setOpenUnlinkClientModal(true);
  };

  const handleAcceptUnlink = () => {
    if (selectedClient && selectedSupplier && selectedSupplier._id) {
      dispatch(deleteStoreBySupplierThunk({ supplierId: selectedSupplier._id, storeId: selectedClient._id }));
    }
    setSelectedClient(null);
    setOpenUnlinkClientModal(false);
  };

  const handleCancelUnlink = () => {
    setSelectedClient(null);
    setOpenUnlinkClientModal(false);
  };

  const getCellHeaderContent = (params: {
    text: string;
    onClick?: (obj: boolean | null) => void;
    extra?: boolean;
    state?: null | boolean;
  }) => {
    return (
      <Grid
        container
        wrap={'nowrap'}
        justify={'space-between'}
        className={params.extra ? classes.extraSpace : undefined}
      >
        <Grid item>
          <Typography style={typo(12, 'bold')}>{params.text}</Typography>
        </Grid>
        {params.onClick && (
          <Grid item className={classes.toggleSelect}>
            <AgoraToggleSort onClick={params.onClick} state={params.state} />
          </Grid>
        )}
      </Grid>
    );
  };

  const getTableHead = () => {
    return (
      <TableHead style={{ background: '#F6F6F6' }}>
        <TableRow className={classes.row}>
          <TableCell>{getCellHeaderContent({ text: 'ID' })}</TableCell>
          <TableCell>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              {getCellHeaderContent({ text: t('name.client'), onClick: setSortName, state: sortName })}
            </Grid>
          </TableCell>
          <TableCell>{getCellHeaderContent({ text: t('contactInfo.address') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: 'Email' })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('contactInfo.phone') })}</TableCell>
          <TableCell>{getCellHeaderContent({ text: t('actions') })}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const getButtons = (store: Store) => {
    const buttons = [];
    if (viewClient) {
      buttons.push(
        <IconButton onClick={() => onClickUnlinkClient(store)}>
          <TrashCanIcon />
        </IconButton>,
      );
    }
    return buttons.map((button, index) => {
      return (
        <React.Fragment key={index}>
          {button}
          {index !== buttons.length - 1 && <Divider orientation="vertical" />}
        </React.Fragment>
      );
    });
  };

  const getRow = (store: Store) => {
    const image = store.storeImage ? store.storeImage : '';
    return (
      <TableRow className={classes.row}>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{store._id}</Typography>
        </TableCell>
        <TableCell padding={'none'}>
          <Grid container wrap={'nowrap'} style={{ padding: 8 }}>
            <Grid item>
              {image ? (
                <img src={image} alt={'store-image'} className={classes.storeImg} />
              ) : (
                <img src={DefaultImage} alt={'store-image'} className={clsx(classes.storeImg, classes.defaultIcon)} />
              )}
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <Typography style={typo(14, 'semi')}>{store.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ ...typo(10, 'semi'), color: '#C6C6C6' }}>{store.address}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{store.address}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{store.buyer?.user?.email ? store.buyer?.user?.email : '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>+30 {store.phone}</Typography>
        </TableCell>
        <TableCell>
          <Grid container justify={'center'}>
            {getButtons(store)}
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const getTableBody = () => {
    return (
      <TableBody className={classes.body}>
        {!loading ? (
          clients.length === 0 ? (
            <TableRow>
              <TableCell padding={'none'} colSpan={5}>
                <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                  <Typography style={{ ...typo(16, 'regular'), color: '#999' }}>{t('msg.data')}</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            clients.map((client, index) => <React.Fragment key={index}>{getRow(client)}</React.Fragment>)
          )
        ) : (
          <TableRow>
            <TableCell padding={'none'} colSpan={5}>
              <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                <CircularProgress style={{ width: 24, height: 24, color: '#999' }} />
              </Grid>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <Table className={classes.root}>
      {getTableHead()}
      {getTableBody()}
      <DialogCancel
        open={openUnlinkClientModal}
        title={t('msg.deleteClient')}
        firstButton={t('accept')}
        secondButton={t('cancel')}
        handleClose={handleCancelUnlink}
        yes={handleAcceptUnlink}
        no={handleCancelUnlink}
      />
    </Table>
  );
};

export default StoresInnerTable;
