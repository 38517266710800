import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUploadFileParams, postFile } from 'agora-admin/src/lib/api/http/requests/file';
import { RootState } from 'agora-admin/src/redux/store';
import {
  Categories,
  Order,
  ORDER_STATUS,
  PostProductType,
  Product,
  PRODUCT_STATUS,
  Store,
  Supplier,
  Unit,
} from 'types-library';
import { deleteSupplierByStoreId } from '../../lib/api/http/requests/store';
import {
  approveStore,
  createProductRequest,
  deleteProductsRequest,
  deleteSupplierRequest,
  getCategoriesRequest,
  getNewCategoriesRequest,
  getOrdersBySupplierRequest,
  getProductsBySupplierRequest,
  getStoresBySupplierRequest,
  getSupplierDataRequest,
  getSuppliersRequest,
  getUnits,
  postSupplierRequest,
  putSupplierRequest,
  updateProductRequest,
} from '../../lib/api/http/requests/supplier';
import { ICreateSupplierBody } from '../../lib/api/http/types/ICreateSupplierBody';
import { Pagination } from '../../lib/api/http/types/Pagination';
import { openToast } from './appSlice';

interface IGetNewCategoriesThunkParams {
  supplierId: string;
}

interface IGetSupplierUnitsThunk {
  supplierId: string;
}
type TreeNode = {
  [key: string]: any;
  children?: TreeNode[];
};

type supplierSliceType = {
  suppliers: Supplier[];
  selectedSupplier: Supplier | null;
  selectedProduct: Product | null;
  orders: Order[];
  stores: Store[];
  products: Product[];
  newCategories: TreeNode[];
  units: Unit[];
  superDrawer: {
    categories: Categories[];
  };
  loading: boolean;
  productsReqLoading: boolean;
  progressBar: number | null;
  clearState: boolean;
  frontPage: boolean;
  loadingPage: boolean;
  pagination: {
    suppliers: number;
    orders: number;
    stores: number;
    products: number;
  };
};

const initialState: supplierSliceType = {
  suppliers: [],
  selectedSupplier: null,
  selectedProduct: null,
  orders: [],
  stores: [],
  products: [],
  loading: false,
  productsReqLoading: false,
  progressBar: null,
  clearState: false,
  frontPage: false,
  loadingPage: false,
  newCategories: [],
  units: [],
  superDrawer: {
    categories: [],
  },
  pagination: {
    suppliers: 0,
    orders: 0,
    stores: 0,
    products: 0,
  },
};

export type RequestParamProps = { skip: number; limit: number; sort?: string; search?: string };
export interface IApproveStoreThunkParams {
  supplierId: string;
  storeId: string;
}

export const approveStoreThunk = createAsyncThunk(
  'supplier/approveStore',
  async (params: IApproveStoreThunkParams, thunkAPI) => {
    try {
      return await approveStore(params.supplierId, params.storeId);
    } catch (error: any) {
      thunkAPI.dispatch(openToast({ text: 'error.approveStore' }));
      throw error;
    }
  },
);
export const createSupplierThunk = createAsyncThunk(
  'supplier/postSupplier',
  async (params: ICreateSupplierBody, thunkAPI) => {
    try {
      const res = await postSupplierRequest({ ...params });
      if (res) {
        thunkAPI.dispatch(openToast({ text: 'msg.verification', success: true }));
      }
    } catch (error: any) {
      if (error && error.response && error.response.data && error.response.data.details) {
        const details = error.response.data.details;
        if (details.includes('User already exists')) {
          thunkAPI.dispatch(openToast({ text: 'msg.user', success: false }));
        }
      } else {
        thunkAPI.dispatch(openToast({ text: 'error.dataSupplier', success: false }));
      }
      throw error;
    }
  },
);

const getSupplierData = createAsyncThunk(
  'supplier/getSuppliersData',
  async (params: { supplierId: string }, thunkAPI) => {
    try {
      const res = await getSupplierDataRequest(params.supplierId);
      thunkAPI.dispatch(setSelectedSupplier(res));
    } catch (error: any) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: 'error.dataSupplier' }));
      throw error;
    }
  },
);

const getSuppliers = createAsyncThunk(
  'supplier/getSuppliers',
  async (params: { params: Pagination; pending?: boolean }, thunkAPI) => {
    try {
      const res = await getSuppliersRequest(params.params, params.pending);
      thunkAPI.dispatch(setSuppliers({ suppliers: res.suppliers, documentsCount: res.suppliersCount }));
    } catch (error: any) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: 'error.dataSupplier' }));
      throw error;
    }
  },
);

const getOrdersBySupplier = createAsyncThunk(
  'supplier/getOrders',
  async (params: { reqParams: RequestParamProps; supplierId: string; status: ORDER_STATUS | null }, thunkAPI) => {
    try {
      const res = await getOrdersBySupplierRequest(params.reqParams, params.supplierId, params.status);
      thunkAPI.dispatch(setOrders({ orders: res.orders, documentsCount: res.ordersCount }));
    } catch (error: any) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: 'error.dataOrders' }));
      throw error;
    }
  },
);

const getStoresBySupplier = createAsyncThunk(
  'supplier/getStores',
  async (params: { reqParams: RequestParamProps; supplierId: string }, thunkAPI) => {
    try {
      const res = await getStoresBySupplierRequest(params.reqParams, params.supplierId);
      const payload = { stores: res.stores, documentsCount: res.storesCount };
      thunkAPI.dispatch(setStores(payload));
    } catch (error: any) {
      console.error(error);

      thunkAPI.dispatch(openToast({ text: 'error.dataStores' }));
      throw error;
    }
  },
);

const getProductsBySupplier = createAsyncThunk(
  'supplier/getProducts',
  async (params: { reqParams: RequestParamProps; status: PRODUCT_STATUS | null; supplierId: string }, thunkAPI) => {
    try {
      const res = await getProductsBySupplierRequest(params.reqParams, params.supplierId, params.status);
      thunkAPI.dispatch(setProducts({ products: res.products, documentsCount: res.productsCount }));
    } catch (error: any) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: 'error.dataProducts' }));
      throw error;
    }
  },
);

const imageUpdateSupplier = createAsyncThunk('store/image/Supplier', async (params: IUploadFileParams, thunkAPI) => {
  try {
    const state: RootState = thunkAPI.getState() as RootState;
    const editSupplier = state.supplier.selectedSupplier;
    const file = await postFile(params);
    if (file.url) {
      if (editSupplier) {
        const supplier = { ...editSupplier, supplierLogo: file.url };
        return await putSupplierRequest(supplier);
      }
    }
  } catch (error: any) {
    console.error(error);
    thunkAPI.dispatch(openToast({ text: 'error.updateSupplier' }));
  }
});

const updateSupplier = createAsyncThunk(
  'store/putSupplier',
  async (params: { supplier: Supplier; callback?: () => void }, thunkAPI) => {
    try {
      await putSupplierRequest(params.supplier);
      if (params.callback) params.callback();
    } catch (error: any) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: 'error.updateSupplier' }));
    }
  },
);

const deleteSupplier = createAsyncThunk(
  'store/putSupplier',
  async (params: { supplier: Supplier; callback: () => void }, thunkAPI) => {
    try {
      if (params.supplier._id) {
        await deleteSupplierRequest(params.supplier._id);
        params.callback();
      }
    } catch (error: any) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: 'error.deleteSupplier' }));
    }
  },
);

const deleteProducts = createAsyncThunk(
  'products/deleteProducts',
  async (params: { productsIds: string[]; callback?: () => void }, thunkAPI) => {
    try {
      await deleteProductsRequest(params.productsIds);
      if (params.callback) params.callback();
    } catch (error: any) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: 'error.deleteProducts' }));
    }
  },
);

const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async (params: { product: PostProductType; updatePriceLists: boolean }, thunkAPI) => {
    try {
      if (!params.product._id) {
        return thunkAPI.rejectWithValue('Failed');
      } else {
        const res = await updateProductRequest(params.product, params.updatePriceLists);
        if (res) {
          thunkAPI.dispatch(
            getProductsBySupplier({
              reqParams: { skip: 0, limit: 10 },
              status: null,
              supplierId: params.product?.supplierId,
            }),
          );
        }
      }
    } catch (error: any) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: 'error.updateProduct' }));
    }
  },
);

const getCategories = createAsyncThunk('products/getCategories', async (params, thunkAPI) => {
  try {
    const res = await getCategoriesRequest();
    thunkAPI.dispatch(setCategories({ categories: res.categories }));
  } catch (error: any) {
    console.error(error);
    thunkAPI.dispatch(openToast({ text: 'error.dataCategories' }));
  }
});

const getNewCategoriesThunk = createAsyncThunk(
  'products/getNewCategories',
  async (params: IGetNewCategoriesThunkParams, thunkAPI) => {
    try {
      const res = await getNewCategoriesRequest(params.supplierId);
      thunkAPI.dispatch(setNewCategories({ categories: res.categories }));
    } catch (error: any) {
      console.error(error);
      thunkAPI.dispatch(openToast({ text: 'error.dataCategories' }));
    }
  },
);

export const getUnitsThunk = createAsyncThunk('units/get', async (params: IGetSupplierUnitsThunk, thunkAPI) => {
  try {
    return await getUnits(params.supplierId);
  } catch (error: any) {
    thunkAPI.dispatch(openToast({ text: 'error.common', success: false }));
    throw Error(error);
  }
});

const createProduct = createAsyncThunk('products/createProduct', async (params: PostProductType, thunkAPI) => {
  try {
    const res = await createProductRequest(params);
    if (res) {
      thunkAPI.dispatch(
        getProductsBySupplier({
          reqParams: { skip: 0, limit: 10 },
          status: null,
          supplierId: res?.supplierId,
        }),
      );
    }
  } catch (error: any) {
    console.error(error);
    thunkAPI.dispatch(openToast({ text: 'error.createProduct' }));
  }
});

const createFileAndProductThunk = createAsyncThunk(
  'file/product/create',
  async (params: { file: IUploadFileParams; product: PostProductType; updatePriceLists: boolean }, thunkAPI) => {
    try {
      const file = await postFile(params.file);
      console.log(file);
      const state: RootState = thunkAPI.getState() as RootState;
      const selected = state.supplier.selectedProduct;
      if (file.url) {
        if (selected && selected._id) {
          const product = { ...params.product, _id: selected._id, productImage: file.url };
          thunkAPI.dispatch(updateProduct({ product, updatePriceLists: params.updatePriceLists }));
        } else {
          const product = { ...params.product, productImage: file.url };
          thunkAPI.dispatch(createProduct(product));
        }
      }
      thunkAPI.dispatch(
        getProductsBySupplier({
          reqParams: { skip: 0, limit: 10 },
          status: null,
          supplierId: params.product?.supplierId,
        }),
      );
    } catch (error: any) {
      thunkAPI.dispatch(openToast({ text: 'error.common', success: false }));
      throw Error(error);
    }
  },
);

export const deleteStoreBySupplierThunk = createAsyncThunk(
  'supplier/deleteStore',
  async (params: { supplierId: string; storeId?: string }, thunkAPI) => {
    try {
      if (params.storeId) {
        const param = { storeId: params.storeId, supplierId: params.supplierId };
        await deleteSupplierByStoreId(param);
        return params.storeId;
      }
    } catch (error: any) {
      thunkAPI.dispatch(openToast({ text: 'error.common' }));
      throw error;
    }
  },
);

const supplierSlice = createSlice({
  name: 'supplier',
  initialState: initialState,
  reducers: {
    setSuppliers(state, action: PayloadAction<{ suppliers: Supplier[]; documentsCount?: number }>) {
      state.suppliers = action.payload.suppliers;
      state.pagination.suppliers = action.payload.documentsCount ? action.payload.documentsCount : 0;
    },
    setCategories(state, action: PayloadAction<{ categories: Categories[] }>) {
      state.superDrawer.categories = action.payload.categories;
    },
    setNewCategories(state, action: PayloadAction<{ categories: TreeNode[] }>) {
      state.newCategories = action.payload.categories;
    },
    setSelectedSupplier(state, action: PayloadAction<Supplier>) {
      state.selectedSupplier = action.payload;
    },
    setSelectedProduct(state, action: PayloadAction<Product>) {
      state.selectedProduct = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setOrders(state, action: PayloadAction<{ orders: Order[]; documentsCount?: number }>) {
      state.orders = action.payload.orders;
      state.pagination.orders = action.payload.documentsCount ? action.payload.documentsCount : 0;
    },
    setStores(state, action: PayloadAction<{ stores: Store[]; documentsCount: number }>) {
      state.stores = action.payload.stores;
      state.pagination.stores = action.payload.documentsCount;
    },
    setProducts(state, action: PayloadAction<{ products: Product[]; documentsCount?: number }>) {
      state.products = action.payload.products;
      state.pagination.products = action.payload.documentsCount ? action.payload.documentsCount : 0;
    },
    setProgressBar(state, action: PayloadAction<number | null>) {
      state.progressBar = action.payload;
    },
    setFrontPage(state, action: PayloadAction<boolean>) {
      state.frontPage = action.payload;
    },
    setLoadingPage(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.frontPage = true;
      }
      state.loadingPage = action.payload;
    },
    setClearState(state, action: PayloadAction<boolean>) {
      state.clearState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSuppliers.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getSuppliers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSuppliers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteStoreBySupplierThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteStoreBySupplierThunk.fulfilled, (state, action) => {
      const newClients = [...state.stores];
      const deleteClientId = action.payload;
      newClients.splice(
        newClients.findIndex((store) => store._id === deleteClientId),
        1,
      );
      state.stores = newClients;
      state.loading = false;
    });
    builder.addCase(deleteStoreBySupplierThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getOrdersBySupplier.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getOrdersBySupplier.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrdersBySupplier.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getStoresBySupplier.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getStoresBySupplier.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStoresBySupplier.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getProductsBySupplier.fulfilled, (state) => {
      state.productsReqLoading = false;
    });
    builder.addCase(getProductsBySupplier.pending, (state) => {
      state.productsReqLoading = true;
    });
    builder.addCase(getProductsBySupplier.rejected, (state) => {
      state.productsReqLoading = false;
    });

    builder.addCase(getUnitsThunk.pending, (state) => {
      state.loadingPage = true;
    });
    builder.addCase(getUnitsThunk.fulfilled, (state, action) => {
      state.units = action.payload.units;
      state.loadingPage = false;
    });
    builder.addCase(getUnitsThunk.rejected, (state) => {
      state.loadingPage = false;
    });

    builder.addCase(createFileAndProductThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createFileAndProductThunk.fulfilled, (state) => {
      state.clearState = true;
      state.loading = false;
    });
    builder.addCase(createFileAndProductThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(approveStoreThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(approveStoreThunk.fulfilled, (state, action: PayloadAction<Supplier>) => {
      state.selectedSupplier = action.payload;
      state.loading = false;
    });
    builder.addCase(approveStoreThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setSuppliers,
  setCategories,
  setNewCategories,
  setOrders,
  setStores,
  setProducts,
  setSelectedSupplier,
  setLoading,
  setFrontPage,
  setProgressBar,
  setLoadingPage,
  setClearState,
  setSelectedProduct,
} = supplierSlice.actions;
export {
  createFileAndProductThunk,
  createProduct,
  deleteProducts,
  deleteSupplier,
  getCategories,
  getNewCategoriesThunk,
  getOrdersBySupplier,
  getProductsBySupplier,
  getStoresBySupplier,
  getSupplierData,
  getSuppliers,
  imageUpdateSupplier,
  updateProduct,
  updateSupplier,
};
export default supplierSlice.reducer;
