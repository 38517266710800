/* eslint-disable react/react-in-jsx-scope */
import { Box, Container, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgoraButton, AgoraSearchPicker, AgoraTextField } from 'ui-components';
import { ReactComponent as CloseIcon } from '../../assets/svgs/x.svg';
import useTranslations from '../../hooks/useTranslations';
import { ICreateSupplierBody } from '../../lib/api/http/types/ICreateSupplierBody';
import { getCountriesThunk } from '../../redux/slices/appSlice';
import { createSupplierThunk } from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';
import BoxContainer from '../common/BoxContainer';

const useStyles = makeStyles(() => ({
  root: {
    background: '#F1F1F1',
    width: '100%',
    maxWidth: 1200,
  },
  appBar: { paddingTop: 20, paddingRight: 16 },
  buttonClose: {
    padding: 0,
  },
  container: {
    '&.MuiContainer-root': {
      paddingRight: 16,
      marginLeft: 80,
      paddingTop: 25,
    },
  },
}));

type AddSupplierModalProps = {
  closeDrawer: () => void;
};

const AddSupplierModal: FC<AddSupplierModalProps> = ({ closeDrawer }) => {
  const classes = useStyles();
  const t = useTranslations();
  const dispatch = useDispatch();

  const countries = useSelector((state: RootState) => state.app.countries);
  const countriesLoading = useSelector((state: RootState) => state.app.countriesLoading);

  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [country, setCountry] = useState<string>('GR');
  const [storeReferral, setStoreReferral] = useState<string>('');

  const [countriesSearchText, setCountriesSearchText] = useState('');

  useEffect(() => {
    dispatch(getCountriesThunk({ searchText: countriesSearchText }));
  }, [countriesSearchText, dispatch]);

  const createSupplier = () => {
    const params: ICreateSupplierBody = {
      email: email,
      name: fullName,
      country,
      companyName,
      storeReferral,
    };
    dispatch(createSupplierThunk(params));
    closeDrawer();
  };

  const AppBar = () => {
    return (
      <Grid container alignItems={'center'} justify={'flex-start'} className={classes.appBar}>
        <Grid container item xs={1} alignItems={'center'} justify={'center'}>
          <IconButton className={classes.buttonClose} onClick={() => closeDrawer()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container item xs={6} justify={'flex-start'} alignItems={'center'}>
          <Typography style={typo(32, 'semi')}>{t('add.supplier')}</Typography>
        </Grid>
        <Grid container item xs={5} justify={'flex-end'} alignItems={'center'} spacing={2}>
          <Grid item xs={4}>
            <AgoraButton
              title={t('cancel')}
              padding={10}
              variant={'outlined'}
              handleClick={closeDrawer}
              background={'white'}
              color={'black'}
            />
          </Grid>
          <Grid item xs={8} md={7} lg={6}>
            <AgoraButton title={t('createAccount.supplier')} padding={10} handleClick={createSupplier} />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid container item xs={12} style={{ marginTop: 12 }}>
          {AppBar()}
        </Grid>
        <Container maxWidth={'md'} className={classes.container}>
          <Grid container item justify={'space-between'} spacing={2} xs={12}>
            <Grid item xs={6}>
              <BoxContainer title={t('data.baseInfo')}>
                <Grid container alignItems={'center'}>
                  <Grid container item alignItems={'center'} justify={'center'} xs={12}>
                    <Grid item xs={12}>
                      <AgoraTextField
                        label={t('nameSur')}
                        radius={'8px 8px 0px 0px'}
                        text
                        value={fullName}
                        setValue={setFullName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        label={t('companyNameSur')}
                        radius={'8px 8px 0px 0px'}
                        text
                        value={companyName}
                        setValue={setCompanyName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        leftBorder={'none'}
                        label={'Email'}
                        radius={'0px 0px 8px 8px'}
                        value={email}
                        setValue={setEmail}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraSearchPicker
                        loading={countriesLoading}
                        data={countries}
                        valueIdentifier="code"
                        labelIdentifier="name"
                        placeholder={t('countrySur')}
                        value={country}
                        searchText={countriesSearchText}
                        setSearchText={setCountriesSearchText}
                        onChange={(v) => setCountry(v)}
                        baseContainerStyle={{ border: '1px solid rgb(227, 227, 227)' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        leftBorder={'none'}
                        label={t('store-referral')}
                        radius={'0px 0px 8px 8px'}
                        value={storeReferral}
                        setValue={setStoreReferral}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </BoxContainer>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
};

export default AddSupplierModal;
