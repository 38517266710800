export const getRandomHue = () => {
  return Math.floor(Math.random() * 360);
};
//
// [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((i, key) => {
//   const hue = getRandomHue();
//   return (
//       <Grid item key={key} xs={1}>
//       <div
//       className={classes.example}
//         style={{
//     background: `linear-gradient(0deg, hsl(${hue},80%,80%) 0%, hsl(${hue + 40},80%,80%) 100%)`,
//
//         }}
//         />
//   </Grid>
// );
// })
