import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type layoutSliceType = {
  header: {
    enabled: boolean;
    mainMenu: boolean;
    progressBar: number | null;
  };
  footer: {
    enabled: boolean;
  };
};

const initialState: layoutSliceType = {
  header: {
    enabled: true,
    mainMenu: false,
    progressBar: null,
  },
  footer: {
    enabled: true,
  },
};

type LayoutType = {
  header?: boolean;
  footer?: boolean;
  mainMenu?: boolean;
  progressBar?: null | number;
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    setLayout(state, action: PayloadAction<LayoutType>) {
      //Always dispatch(setLayout(boolean)) to reset the components
      //in the beginning of a page before dispatching any other
      //layout oriented action
      const params = action.payload;
      state.header.enabled = params.header ? params.header : false;
      state.footer.enabled = params.footer ? params.footer : false;
      state.header.mainMenu = params.mainMenu ? params.mainMenu : false;
      state.header.progressBar = params.progressBar !== undefined ? params.progressBar : null;
    },
    setMainMenu(state, action: PayloadAction<boolean>) {
      state.header.mainMenu = action.payload;
    },
    setHeader(state, action: PayloadAction<boolean>) {
      state.header.enabled = action.payload;
    },
    setFooter(state, action: PayloadAction<boolean>) {
      state.footer.enabled = action.payload;
    },
    setProgressBar(state, action: PayloadAction<number | null>) {
      state.header.progressBar = action.payload;
    },
  },
});

export const { setLayout, setMainMenu, setHeader, setFooter, setProgressBar } = layoutSlice.actions;
export default layoutSlice.reducer;
