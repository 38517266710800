import React, { useState, FC } from 'react';
import { Typography } from '@material-ui/core';
import { typo } from '../../../utils/typo';
import { getCountStoresRequest } from '../../../lib/api/http/requests/buyer';
import { useEffect } from 'react';

type CounterStoreCellProps = {
  buyerId: string | undefined;
};

const CountStoreCell: FC<CounterStoreCellProps> = ({ buyerId }) => {
  const [count, setCount] = useState<string>('');

  useEffect(() => {
    if (buyerId) {
      getCount();
    } else {
      setCount('N/A');
    }
  }, [buyerId]);

  const getCount = async () => {
    try {
      if (buyerId) {
        const res = await getCountStoresRequest(buyerId);
        if (res) {
          setCount(res.storesCount);
        }
      }
    } catch (error) {
      setCount('N/A');
    }
  };

  return <Typography style={typo(12, 'bold')}> {count} </Typography>;
};

export default CountStoreCell;
