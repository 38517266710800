import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { openToast } from './appSlice';
import { Order } from 'types-library';
import { getAllOrdersRequest } from '../../lib/api/http/requests/order';

type OrderSliceType = {
  data: {
    orders: Order[];
    ordersCount: number;
    ordersLoading: boolean;
  };
};
const initialState: OrderSliceType = {
  data: {
    orders: [],
    ordersCount: 0,
    ordersLoading: false,
  },
};

export const getAllOrders = createAsyncThunk(
  'orders/getAllOrders',
  async (
    params: { skip: number; limit: number; searchText: string; status: string | null; sort: string },
    thunkAPI,
  ) => {
    try {
      const { documents, documentsCount } = await getAllOrdersRequest(params);
      return { documents, documentsCount };
    } catch (error) {
      thunkAPI.dispatch(openToast({ text: 'error.dataOrders' }));
      throw error;
    }
  },
);

const orderSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllOrders.pending, (state) => {
      state.data.ordersLoading = true;
    });
    builder.addCase(getAllOrders.fulfilled, (state, action) => {
      state.data.orders = action.payload.documents;
      state.data.ordersCount = action.payload.documentsCount;
      state.data.ordersLoading = false;
    });
    builder.addCase(getAllOrders.rejected, (state) => {
      state.data.ordersLoading = true;
    });
  },
});

export default orderSlice.reducer;
