import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import HiddenTableActions from './HiddenTableActions';

const useStyles = makeStyles((theme) => ({
  root: {},
  contentContainer: {
    padding: theme.spacing(2, 2),
    background: theme.palette.secondary.main,
    zIndex: 100,
    position: 'relative',
  },
  hiddenContainer: {
    zIndex: 90,
    position: 'relative',
  },
}));

type CustomTableHeadProps = {
  selectedTab?: number;
  visibleHiddenActions?: boolean;
  actionDeleteSelected?: () => void;
};

const CustomTableHead: React.FC<CustomTableHeadProps> = ({ children, visibleHiddenActions, actionDeleteSelected }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>{children}</Box>
      {visibleHiddenActions !== undefined && (
        <Box className={classes.hiddenContainer}>
          <HiddenTableActions visible={visibleHiddenActions} deleteSelected={actionDeleteSelected} />
        </Box>
      )}
    </Box>
  );
};

export default CustomTableHead;
