import { Grid, makeStyles, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Product, Store, Unit } from 'types-library';
import { AgoraNumberField, AgoraSearchPicker } from 'ui-components';
import { dispatch } from '../..';
import { useDebounce } from '../../hooks/useDebounce';
import useTranslations from '../../hooks/useTranslations';
import { openToast } from '../../redux/slices/appSlice';
import { createPriceListThunk } from '../../redux/slices/priceListSlice';
import { getProductsBySupplier, getStoresBySupplier } from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';

const RESULTS_PER_PAGE = 100000;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  gridTable: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  body: {
    '& > *:last-child .MuiTableCell-root': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  toggleSelect: {
    position: 'relative',
    right: -12,
  },
  row: {
    '& .MuiTableCell-root:not(:last-child)': {
      borderRight: '1px solid #E3E3E3',
    },
  },
  rowEdit: {
    '& .MuiTableCell-root:not(:last-child)': {
      borderRight: '1px solid #E3E3E3',
    },
    background: '#FFFBF5',
  },
  selectCell: {
    height: '100%',
    marginRight: theme.spacing(1),
    borderRight: '1px solid #E3E3E3',
  },
  extraSpace: {
    minWidth: 250,
  },
  storeImg: {
    width: 40,
    height: 40,
    maxWidth: 40,
    maxHeight: 40,
    overflow: 'hidden',
    border: '1px solid ' + theme.palette.secondary.dark,
    borderRadius: '40%',
    marginRight: theme.spacing(1),
  },
  defaultIcon: {
    padding: '4px 6px 4px 4px',
  },
  actionButton: {
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    width: 120,
  },
  actionButtonActive: {
    background: theme.palette.secondary.main,
  },
  addPricelistButton: {
    width: '100%',
    height: '100%',
  },
  image: {
    width: 40,
    height: 40,
    padding: 5,
    objectFit: 'cover',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid ' + theme.palette.secondary.dark,
    borderRadius: '40%',
    marginRight: theme.spacing(1),
  },
  saved: {
    animation: `$savedGreen 1s `,
  },
  '@keyframes savedGreen': {
    '0%': {
      background: '#FFFBF5',
    },
    '10%': {
      background: '#dcffe0',
    },
    '100%': {
      background: 'white',
    },
  },
}));

const AddProductPricelist = () => {
  const classes = useStyles();
  const t = useTranslations();
  const theme = useTheme();

  const products: Product[] = useSelector((state: RootState) => state.supplier.products);
  const stores: Store[] = useSelector((state: RootState) => state.supplier.stores);
  const units: Unit[] = useSelector((state: RootState) => state.supplier.units);
  const productsReqLoading = useSelector((state: RootState) => state.supplier.productsReqLoading);

  const [productSearchTerm, setProductSearchTerm] = useState<string>('');
  const debouncedProductSearchTerm = useDebounce(productSearchTerm, 500);

  const [storeSearchTerm, setStoreSearchTerm] = useState<string>('');
  const debouncedStoreSearchTerm = useDebounce(storeSearchTerm, 500);

  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [selectedStore, setSelectedStore] = useState<string | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);
  const [selectedPriceMap, setSelectedPriceMap] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    dispatch(
      getProductsBySupplier({
        reqParams: { skip: 0, limit: RESULTS_PER_PAGE, search: debouncedProductSearchTerm },
        status: null,
        supplierId,
      }),
    );
  }, [debouncedProductSearchTerm]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    dispatch(
      getStoresBySupplier({
        reqParams: { skip: 0, limit: RESULTS_PER_PAGE, search: debouncedStoreSearchTerm },
        supplierId,
      }),
    );
  }, [debouncedStoreSearchTerm]);

  return (
    <>
      <Grid item xs={12} md={4} style={{ padding: '0.5rem' }}>
        <AgoraSearchPicker
          data={products}
          valueIdentifier="_id"
          labelIdentifier="name"
          placeholder="Προϊόν"
          searchText={productSearchTerm}
          setSearchText={setProductSearchTerm}
          loading={productsReqLoading}
          mainContainerStyle={{ border: '1px solid rgb(227, 227, 227)', borderRadius: '0.5rem' }}
          onChange={(value: string) => {
            const product = products.find((p) => p._id === value);
            if (!product) {
              return;
            }
            const priceMap: { [key: string]: number } = {};
            for (const productUnit of product.units) {
              priceMap[productUnit.unit] = productUnit.price || 0;
            }
            setSelectedPriceMap(priceMap);
            setSelectedProduct(value);
            setSelectedUnit(null);
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: '0.5rem' }}>
        <AgoraSearchPicker
          data={stores}
          valueIdentifier="_id"
          labelIdentifier="name"
          placeholder="Κατάστημα"
          mainContainerStyle={{ border: '1px solid rgb(227, 227, 227)', borderRadius: '0.5rem' }}
          searchText={storeSearchTerm}
          setSearchText={setStoreSearchTerm}
          onChange={(value: string) => {
            setSelectedStore(value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: '0.5rem' }}>
        <AgoraSearchPicker
          data={
            products
              .find((e) => e._id === selectedProduct)
              ?.units.map((e) => ({
                ...e,
                ...units.find((u) => u._id === e.unit),
              })) || []
          }
          valueIdentifier="unit"
          labelIdentifier="name"
          placeholder="Μονάδα"
          mainContainerStyle={{ border: '1px solid rgb(227, 227, 227)', borderRadius: '0.5rem' }}
          searchText={storeSearchTerm}
          setSearchText={setStoreSearchTerm}
          onChange={(value: string) => {
            setSelectedUnit(value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: '0.5rem' }}>
        <AgoraNumberField
          text
          typeText={'number'}
          label={t('ui-lib.price')}
          radius={'0.5rem'}
          value={selectedUnit ? selectedPriceMap[selectedUnit].toString() : '0'}
          setValue={(value: number) => {
            const tmpPriceMap = { ...selectedPriceMap };
            if (!selectedUnit) {
              return;
            }
            tmpPriceMap[selectedUnit] = value;
            setSelectedPriceMap(tmpPriceMap);
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: '0.5rem' }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.addPricelistButton}
          onClick={() => {
            if (
              selectedProduct === '' ||
              !selectedProduct ||
              selectedStore === '' ||
              !selectedStore ||
              selectedUnit === '' ||
              !selectedUnit ||
              !Object.values(selectedPriceMap).every((v) => !isNaN(v))
            ) {
              dispatch(openToast({ text: 'error.add-pricelist-missing-data' }));
              return;
            }
            dispatch(
              createPriceListThunk({
                product: selectedProduct,
                store: selectedStore,
                priceMap: selectedPriceMap,
              }),
            );
          }}
        >
          {t('add.pricelist')}
        </Button>
      </Grid>
    </>
  );
};

export default AddProductPricelist;
