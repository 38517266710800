import {
  Button,
  InputAdornment,
  ListItemText,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import mime from 'mime';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Product, PRODUCT_STATUS } from 'types-library';
import { AgoraButton, AgoraDrawer, AgoraMultiSelect, AgoraSelect } from 'ui-components';
import { dispatch } from '../..';
import useTranslations from '../../hooks/useTranslations';
import { openToast } from '../../redux/slices/appSlice';
import {
  createFileAndProductThunk,
  createProduct,
  getNewCategoriesThunk,
  getUnitsThunk,
  updateProduct,
} from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';
import { isStringEmptyOrNullOrUndefined } from '../../utils/isStringEmptyOrNullOrUndefined';
import { typo } from '../../utils/typo';

/* eslint-disable react/react-in-jsx-scope */
export interface IAddProductDrawerProps {
  open: boolean;
  closeDrawer: () => void;
  mode: 'view-only' | 'create' | 'edit';
  product?: Product;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    position: 'relative',
    background: 'white',
    width: '100%',
    height: '100%',
    maxWidth: 1200,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  textInput: {
    width: '20rem',
  },
  wrapImg: {
    position: 'relative',
  },
  deleteImg: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  photoContainer: {
    border: 'solid 1px #E3E3E3',
    borderRadius: '8px',
    width: '20rem',
    padding: '1rem',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    gap: '1rem',
  },
  unitsSelect: {
    '& fieldset': {
      border: 'none',
    },
  },
}));
const AddProductDrawer = (props: IAddProductDrawerProps) => {
  const classes = useStyles();
  const t = useTranslations();

  const productStatusData = [
    { value: t('ui-lib.publishingProduct'), status: PRODUCT_STATUS.ACTIVE },
    { value: t('ui-lib.draftProduct'), status: PRODUCT_STATUS.INACTIVE },
    { value: t('ui-lib.stockProduct'), status: PRODUCT_STATUS.OUT_OF_STOCK },
    { value: t('ui-lib.uponRequestProduct'), status: PRODUCT_STATUS.UPON_REQUEST },
  ];

  const units = useSelector((state: RootState) => state.supplier.units);
  const selectedSupplier = useSelector((state: RootState) => state.supplier.selectedSupplier);
  const newCategories = useSelector((state: RootState) => state.supplier.newCategories);

  const imageRef = useRef(null);
  const [product, setProduct] = useState<any>(
    props.product || {
      name: '',
      productCode: '',
      categories: [],
      brandName: '',
      deal: false,
      description: '',
      supplierId: selectedSupplier?._id,
      status: PRODUCT_STATUS.ACTIVE,
      image: null,
      productImage: null,
      fromBuyer: null,
      allowedStores: [],
      units: [],
    },
  );
  const [selectedUnit, setSelectedUnit] = useState(units[0]?._id);
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    if (props.product) {
      setProduct(props.product);
    }
  }, [props.product]);
  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    dispatch(getNewCategoriesThunk({ supplierId }));
  }, [selectedSupplier]);

  useEffect(() => {
    const supplierId = location.pathname.split('/')[2];
    dispatch(getUnitsThunk({ supplierId }));
  }, [selectedSupplier]);

  const onClickImage = () => {
    if (imageRef !== null && imageRef.current !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      imageRef?.current.click();
    }
  };
  return (
    <AgoraDrawer open={props.open} closeDrawer={props.closeDrawer}>
      <div className={classes.root}>
        <Typography style={{ color: 'black', fontSize: '1rem', fontWeight: 'bold' }}>
          {t('ui-lib.create-product')}
        </Typography>
        <TextField
          className={classes.textInput}
          autoCapitalize={'off'}
          autoComplete={'off'}
          value={product.name}
          onChange={(e) => {
            setProduct({
              ...product,
              name: e.target.value,
            });
          }}
          type={'text'}
          label={t('ui-lib.product-name')}
          fullWidth
          variant="outlined"
        />
        <TextField
          className={classes.textInput}
          autoCapitalize={'off'}
          autoComplete={'off'}
          value={product.brandName}
          onChange={(e) => {
            setProduct({
              ...product,
              brandName: e.target.value,
            });
          }}
          type={'text'}
          label={t('ui-lib.brand-name')}
          fullWidth
          variant="outlined"
        />

        <AgoraMultiSelect
          data={newCategories}
          value={product.categories}
          titleKey="name"
          asDropdown
          placeholder="Categories"
          includeParentCategoriesInValue
          onChange={(value: string[]) =>
            setProduct({
              ...product,
              categories: value,
            })
          }
        />
        <TextField
          className={classes.textInput}
          autoCapitalize={'off'}
          autoComplete={'off'}
          value={product.productCode}
          onChange={(e) => {
            setProduct({
              ...product,
              productCode: e.target.value,
            });
          }}
          type={'text'}
          label={t('ui-lib.product-code')}
          fullWidth
          variant="outlined"
        />
        <TextField
          className={classes.textInput}
          autoCapitalize={'off'}
          autoComplete={'off'}
          value={product.description}
          onChange={(e) => {
            setProduct({
              ...product,
              description: e.target.value,
            });
          }}
          type={'text'}
          label={t('ui-lib.product-description')}
          fullWidth
          variant="outlined"
        />

        <AgoraSelect
          color={
            status === productStatusData[2].value
              ? '#D75858'
              : status === productStatusData[3].value
              ? 'rgba(252, 194, 3, 1)'
              : '#50BC8F'
          }
          data={productStatusData}
          value={productStatusData.find((s) => s.status === product.status)?.value || product.status}
          setValue={(e) => {
            console.log(e);
            setProduct({
              ...product,
              status: productStatusData.find((s) => s.value === e)?.status,
            });
          }}
          customPadding
        />
        <div className={classes.photoContainer}>
          <div onClick={() => onClickImage()}>
            <div>
              <Typography style={{ ...typo(12, 'regular'), textAlign: 'center', color: '#9E9898' }}>
                {t('ui-lib.drag-image')}
              </Typography>
            </div>
            <div>
              <img src={product.productImage} style={{ width: '100%', height: '10rem', marginTop: '1rem' }} />
            </div>
            <input
              ref={imageRef}
              accept="image/*"
              type="file"
              id="contained-button-file"
              style={{ display: 'none' }}
              onChange={(event: any) => {
                const file = event.target.files[0];
                const reader = new FileReader();
                if (!file) return;
                reader.readAsDataURL(file);
                reader.onload = function () {
                  if (reader.result) {
                    setProduct({
                      ...product,
                      productImage: reader.result as string,
                    });
                    setImage(reader.result as string);
                  }
                };
                reader.onerror = function () {
                  return;
                };
              }}
            />
          </div>

          <div>
            <Button
              style={{ backgroundColor: '#f6f6f6', width: '100%' }}
              onClick={() => {
                setProduct({
                  ...product,
                  productImage: null,
                });
                setImage('');
              }}
            >
              Remove
            </Button>
          </div>
        </div>
        <div>
          <TextField
            className={classes.textInput}
            autoCapitalize={'off'}
            autoComplete={'off'}
            value={product.units.find((e: any) => e.unit === selectedUnit)?.price || ''}
            onChange={(e) => {
              const tmpUnits = [...product.units];
              const selectedUnitIndex = tmpUnits.findIndex((e) => e.unit === selectedUnit);

              console.log(selectedUnitIndex);
              if (selectedUnitIndex < 0) {
                tmpUnits.push({
                  unit: selectedUnit,
                  price: e.target.value,
                  availability: 100000,
                  minimum: 1,
                });
              } else {
                tmpUnits[selectedUnitIndex] = {
                  unit: selectedUnit,
                  price: e.target.value,
                  availability: 100000,
                  minimum: 1,
                };
              }

              setProduct({
                ...product,
                units: tmpUnits,
              });
            }}
            type={'number'}
            label={t('ui-lib.price')}
            fullWidth
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Select
                    className={classes.unitsSelect}
                    disableUnderline
                    style={{ border: 'none' }}
                    value={selectedUnit}
                    variant="standard"
                    onChange={(e: any) => {
                      console.log(e.target.value);
                      setSelectedUnit(e.target.value);
                    }}
                    renderValue={(selected: any) => {
                      const unit = units.find((c) => c._id == selected);
                      return unit?.name || '';
                    }}
                    label={t('ui-lib.product-allowed-stores-select-placeholder')}
                    input={<OutlinedInput label={t('ui-lib.product-allowed-stores-select-placeholder')} />}
                  >
                    {units.map((u) => {
                      if (u) {
                        return (
                          <MenuItem key={u._id} value={u._id}>
                            <ListItemText primary={u.name} />
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    style={{
                      ...typo(16, 'semi'),
                    }}
                  >
                    {selectedSupplier?.preferences?.currency || '€'}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <AgoraButton
            title={t('save')}
            padding={10}
            handleClick={() => {
              if (product.units.length === 0) {
                dispatch(openToast({ text: 'Fill in the price for at least one unit' }));
                return;
              }
              if (product.units.some((e: any) => isNaN(Number(e?.price)))) {
                dispatch(openToast({ text: 'Only number inputs allowed for unit prices' }));
                return;
              }
              if (props.mode === 'create') {
                if (product.image && !isStringEmptyOrNullOrUndefined(image)) {
                  const file = {
                    folder: 'products',
                    name:
                      product.name.replace(/ /g, '') +
                      '.' +
                      mime.getExtension(image.split(',')[0]?.split(';')[0].split(':')[1]),
                    data: image,
                  };

                  const params = { file: file, product: product, updatePriceLists: false };
                  dispatch(createFileAndProductThunk(params));
                } else {
                  dispatch(createProduct(product));
                }
                setProduct({
                  name: '',
                  productCode: '',
                  categories: [],
                  brandName: '',
                  deal: false,
                  description: '',
                  supplierId: selectedSupplier?._id,
                  status: PRODUCT_STATUS.ACTIVE,
                  image: null,
                  fromBuyer: null,
                  allowedStores: [],
                  units: [],
                });
                props.closeDrawer();
              } else if (props.mode === 'edit') {
                if (product.productImage && !isStringEmptyOrNullOrUndefined(image)) {
                  const file = {
                    folder: 'products',
                    name:
                      product.name.replace(/ /g, '') +
                      '.' +
                      mime.getExtension(image.split(',')[0]?.split(';')[0].split(':')[1]),
                    data: image,
                  };

                  const params = { file: file, product: product, updatePriceLists: false };
                  dispatch(createFileAndProductThunk(params));
                } else {
                  dispatch(updateProduct({ product: product, updatePriceLists: false }));
                }

                props.closeDrawer();
              }
            }}
          />
        </div>
      </div>
    </AgoraDrawer>
  );
};

export default AddProductDrawer;
