/* eslint-disable react/react-in-jsx-scope */
import { Avatar, Divider, Grid, MenuItem, Theme, Typography, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { Supplier } from 'types-library';
import { getRandomHue } from '../../utils/getRandomHue';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme: Theme) => ({
  minus: {
    border: '1px solid #E3E3E3',
    marginRight: theme.spacing(1),
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
  menuItem: {
    margin: theme.spacing(0, 1),
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    '&:hover:not(.Mui-selected)': {
      color: theme.palette.info.main,
      background: 'none',
    },
  },
  selected: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
    fill: theme.palette.secondary.main,
    borderRadius: '50%',
  },
  icon: {
    width: '32px',
    height: '32px',
    borderRadius: '11px',
    padding: '8px',
    backdropFilter: 'blur(13.2719px)',
    '&.MuiAvatar-colorDefault': {
      background: (props: { hue: number }) =>
        `linear-gradient(0deg, hsl(${props.hue},80%,80%) 0%, hsl(${props.hue + 40},80%,80%) 100%)`,
      backdropFilter: 'blur(13.2719px)',
    },
  },
  img: {
    width: '32px',
    height: '32px',
    borderRadius: '11px',
  },
}));

export type SelectedBuyerProp = {
  supplier: Supplier;
  selected?: boolean;
  setSelected?: () => void;
};

const SelectedBuyer: FC<SelectedBuyerProp> = ({ supplier, setSelected, selected }) => {
  const [hue] = useState<number>(getRandomHue());
  const classes = useStyles({ hue });

  const { companyDetails = { name: 'N/A' }, supplierDetails = { fullName: 'N/A' } } = supplier ? supplier : {};
  const { name } = companyDetails;
  const { fullName } = supplierDetails;
  const url = supplier.supplierLogo ? supplier.supplierLogo : undefined;

  return (
    <Grid container spacing={1}>
      <MenuItem className={classes.menuItem}>
        <Grid container alignItems={'center'} spacing={1}>
          {setSelected && (
            <Grid item onClick={setSelected}>
              <CheckIcon className={clsx(classes.minus, selected && classes.selected)} />
            </Grid>
          )}
          <Grid item>
            <Avatar src={url} className={url ? classes.img : classes.icon} alt={name}>
              <Typography style={typo(12, 'bold')} color={'secondary'}>
                {name
                  .split(' ')
                  .slice(0, 2)
                  .map((i) => {
                    return i.charAt(0).toUpperCase();
                  })}
              </Typography>
            </Avatar>
          </Grid>
          <Grid item style={{ maxWidth: 600, whiteSpace: 'normal' }}>
            <Typography style={typo(14, 'semi')} color={'primary'}>
              {fullName} / {name}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>

      <Grid container item xs={12}>
        <Divider variant="middle" />
      </Grid>
    </Grid>
  );
};

export default SelectedBuyer;
