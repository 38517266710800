import LoginForm from '../components/LoginForm';
import React from 'react';
import loginImage from '../assets/images/loginImage.png';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AgoraLanguages } from 'ui-components';

type LoginPageProps = unknown;

const LoginPage: React.FC<LoginPageProps> = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flex: 1,
      minHeight: '100vh',
    },
    loginImage: {
      position: 'absolute',
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
    fullheight: {
      height: '100%',
    },
    header: {
      fontSize: theme.spacing(2),
      marginTop: '20px',
      marginLeft: '68px',
    },
    imageContainer: {
      position: 'relative',
    },
  }));

  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item container xs={12} md={7}>
        <Grid item xs={6}>
          <Typography className={classes.header}>Orderit</Typography>
        </Grid>
        <Grid item xs={6}>
          <AgoraLanguages />
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth="sm">
            <LoginForm />
          </Container>
        </Grid>
      </Grid>
      {matches ? (
        <Grid item xs={12} md={5} className={classes.imageContainer}>
          <img className={classes.loginImage} src={loginImage}></img>
        </Grid>
      ) : null}
    </Grid>
  );
};
export default LoginPage;
