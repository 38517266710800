import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';

type AuthRoutesProps = unknown;

const AuthRoutes: FC<AuthRoutesProps> = ({}) => {
  return (
    <Switch>
      <Route path="/auth">
        <LoginPage />
      </Route>
      <Route>
        <Redirect to={'/auth'} />
      </Route>
    </Switch>
  );
};

export default AuthRoutes;
