import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_STATUS } from 'types-library';
import { AgoraSearchInput } from 'ui-components';
import PaginationFilter from '../../components/common/PaginationFilter';
import CustomTableBody from '../../components/table/CustomTableBody';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import CustomTableHead from '../../components/table/CustomTableHead';
import OrdersInnerTable from '../../components/table/OrdersInnerTable';
import TableTabs from '../../components/table/TableTabs';
import { useDebounce } from '../../hooks/useDebounce';
import useTranslations from '../../hooks/useTranslations';
import { PaginationOrder } from '../../lib/api/http/types/PaginationOrder';
import { setLayout } from '../../redux/slices/layoutSlice';
import { getOrdersByStoreThunk, getStoreIdThunk } from '../../redux/slices/storeSlice';
import { RootState } from '../../redux/store';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 93,
  },
}));

type BuyerOrdersPageProps = unknown;
const BuyerOrdersPage: React.FC<BuyerOrdersPageProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslations();

  const loading = useSelector((state: RootState) => state.store.meta.loading);
  const { selectedStore, orders } = useSelector((state: RootState) => state.store.data);
  const maxOrdersCount = useSelector((state: RootState) => state.store.data.pagination.orders);

  const [url, setUrl] = useState<string>('');
  const [tab, setTab] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<string>('');
  const [codeSort, setCodeSort] = useState<boolean | null>(null);
  const [costSort, setCostSort] = useState<boolean | null>(null);
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [createdDateSort, setCreatedDateSort] = useState<boolean | null>(false);
  const [deliveryDateSort, setDeliveryDateSort] = useState<boolean | null>(null);
  const [selectedOrdersIds, setSelectedOrdersIds] = useState<string[]>([]);

  const tabs = [
    t('status.all'),
    t('ui-lib.pending-rfq'),
    t('status.accepted'),
    t('status.delivered'),
    t('status.rejected'),
    t('status.canceled'),
  ];

  const debouncedSearchText = useDebounce(searchInput, 500);

  // Layout Config Effect
  useEffect(() => {
    dispatch(setLayout({ header: true, mainMenu: true, footer: true }));
  }, [dispatch]);

  useEffect(() => {
    const splitPath = window.location.pathname.split('/')[2];
    setUrl(splitPath);
  }, []);

  //setStore
  useEffect(() => {
    if (url) {
      dispatch(getStoreIdThunk(url));
    }
  }, [dispatch, url]);

  const resetSorts = () => {
    setDeliveryDateSort(null);
    setCreatedDateSort(null);
    setCodeSort(null);
    setDeliveryDateSort(null);
    setCreatedDateSort(null);
  };

  useEffect(() => {
    let status = '';
    switch (tab) {
      case 0:
        //nothing
        break;
      case 1:
        status = ORDER_STATUS.PENDING_RFQ;
        break;
      case 2:
        status = ORDER_STATUS.ACCEPTED;
        break;
      case 3:
        status = ORDER_STATUS.DELIVERED;
        break;
      case 4:
        status = ORDER_STATUS.REJECTED;
        break;
      case 5:
        status = ORDER_STATUS.CANCELED;
        break;
    }
    let params: PaginationOrder = {
      skip: page * 10,
      limit: 10,
      sort: '',
      search: debouncedSearchText,
      status: status,
      storeId: '',
    };
    if (deliveryDateSort !== null) {
      params = { ...params, sort: deliveryDateSort ? 'deliveryDate' : '-deliveryDate' };
    }
    if (createdDateSort !== null) {
      params = { ...params, sort: createdDateSort ? 'createdAt' : '-createdAt' };
    }
    if (costSort !== null) {
      params = { ...params, sort: costSort ? 'cost' : '-cost' };
    }
    if (codeSort !== null) {
      params = { ...params, sort: codeSort ? 'orderNumber' : '-orderNumber' };
    }

    if (selectedStore) {
      dispatch(getOrdersByStoreThunk(params));
    }
  }, [
    dispatch,
    deliveryDateSort,
    createdDateSort,
    costSort,
    codeSort,
    page,
    tab,
    debouncedSearchText,
    selectedStore,
    refreshCounter,
  ]);

  const refreshOrders = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const handleSetDeliverySort = (sort: boolean | null) => {
    resetSorts();
    setDeliveryDateSort(sort);
  };

  const handleSetCreatedSort = (sort: boolean | null) => {
    resetSorts();
    setCreatedDateSort(sort);
  };

  const handleSetCodeSort = (sort: boolean | null) => {
    resetSorts();
    setCodeSort(sort);
  };

  const handleSetCostSort = (sort: boolean | null) => {
    resetSorts();
    setCostSort(sort);
  };

  return (
    <Container maxWidth={'lg'} className={classes.root}>
      <Box mt={6}>
        <CustomTableContainer>
          <CustomTableHead visibleHiddenActions={!!selectedOrdersIds.length}>
            <Grid container justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
              <Grid item>
                <TableTabs tabs={tabs} selectedTab={tab} setSelectedTab={setTab} />
              </Grid>
              <Grid item container style={{ flex: 0 }} wrap={'nowrap'}>
                <AgoraSearchInput placeholder={t('search.orders')} changeSearch={setSearchInput} scroll={false} />
                <PaginationFilter
                  page={page}
                  setPage={(page) => setPage(page)}
                  pageSize={10}
                  allCount={maxOrdersCount}
                  style={{ marginLeft: 16 }}
                />
              </Grid>
            </Grid>
          </CustomTableHead>
          <CustomTableBody>
            <OrdersInnerTable
              showSupplierName
              refreshOrders={refreshOrders}
              orders={orders}
              deliveryDateSort={deliveryDateSort}
              setDeliveryDateSort={handleSetDeliverySort}
              createdDateSort={createdDateSort}
              setCreatedDateSort={handleSetCreatedSort}
              costSort={costSort}
              setCostSort={handleSetCostSort}
              codeSort={codeSort}
              setCodeSort={handleSetCodeSort}
              loading={loading}
            />
          </CustomTableBody>
        </CustomTableContainer>
      </Box>
    </Container>
  );
};

export default BuyerOrdersPage;
