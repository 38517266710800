import { getRequest, postRequest, putRequest } from '../index';
import { Units } from 'types-library';
const getPriceListRequest = (
  params: {
    skip: number;
    limit: number;
    searchText: string;
    storeId: string;
  },
  supplierId: string,
) => {
  return getRequest(`/agoraServices/admin/getPriceLists`, {
    ...params,
    supplierId: supplierId,
  });
};

const putPriceListRequest = (params: { priceListId: string; priceMap: { [key: string]: number } }) => {
  return putRequest(`/agoraServices/admin/updatePriceList/${params.priceListId}`, {
    priceListId: params.priceListId,
    priceMap: params.priceMap,
  });
};

const createPriceListRequest = (params: { store: string; product: string; priceMap: { [key: string]: number } }) => {
  return postRequest(`/agoraServices/admin/pricelists`, params);
};

export { getPriceListRequest, putPriceListRequest, createPriceListRequest };
