import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { fade, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Order, ORDER_STATUS } from 'types-library';
import { AgoraOrderSuperDrawer, AgoraToggleSort, getOrderTotalCost } from 'ui-components';
import DefaultImage from '../../assets/svgs/default-product.svg';
import useTranslations from '../../hooks/useTranslations';
import {
  acceptOrder,
  declineOrder,
  deliverOrder,
  discountOrder,
  getStorePricelistsThunk,
  partialAcceptOrder,
  setSelectedOrder,
} from '../../redux/slices/storeSlice';
import { getUnitsThunk } from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme) => ({
  body: {
    '& > *:last-child .MuiTableCell-root': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  row: {
    '& .MuiTableCell-root:not(:last-child)': {
      borderRight: '1px solid #E3E3E3',
    },
  },
  toggleSelect: {
    position: 'relative',
    right: -12,
  },
  selectCell: {
    height: '100%',
    marginRight: theme.spacing(1),
    borderRight: '1px solid #E3E3E3',
  },
  extraSpace: {
    minWidth: 150,
  },
  storeImg: {
    width: 40,
    height: 40,
    maxWidth: 40,
    maxHeight: 40,
    overflow: 'hidden',
    border: '1px solid ' + theme.palette.secondary.dark,
    borderRadius: '40%',
    marginRight: theme.spacing(1),
  },
  defaultIcon: {
    padding: '4px 6px 4px 4px',
  },
  actionButton: {
    cursor: 'pointer',
    userSelect: 'none',
    background: theme.palette.info.main,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 3),
    borderRadius: 8,
  },
}));

type OrdersInnerTableProps = {
  orders: Order[];
  showSupplierName?: boolean;
  loading?: boolean;
  refreshOrders?: () => void;
  selectedOrdersIds?: string[];
  setSelectedOrdersIds?: (ids: string[]) => void;
  createdDateSort: null | boolean;
  setCreatedDateSort: (bool: boolean | null) => void;
  deliveryDateSort: null | boolean;
  setDeliveryDateSort: (bool: boolean | null) => void;
  costSort: null | boolean;
  setCostSort: (bool: boolean | null) => void;
  codeSort: null | boolean;
  setCodeSort: (bool: boolean | null) => void;
};

const OrdersInnerTable: React.FC<OrdersInnerTableProps> = ({
  orders,
  refreshOrders,
  loading,
  createdDateSort,
  setCreatedDateSort,
  deliveryDateSort,
  setDeliveryDateSort,
  costSort,
  setCostSort,
  codeSort,
  setCodeSort,
  showSupplierName,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const t = useTranslations();

  const selectedOrder = useSelector((state: RootState) => state.store.data.selectedOrder);
  const units = useSelector((state: RootState) => state.supplier.units);
  const pricelists = useSelector((state: RootState) => state.store.data.pricelists);

  const [showOrder, setShowOrder] = useState<boolean>(false);

  useEffect(() => {
    const supplierId = selectedOrder?.supplier?._id;
    if (supplierId) {
      dispatch(getUnitsThunk({ supplierId: supplierId || '' }));
    }
  }, [dispatch, selectedOrder]);

  useEffect(() => {
    dispatch(
      getStorePricelistsThunk({
        order: orders?.map((e) => e.orderNumber.toString()).join(','),
      }),
    );
  }, [dispatch, orders]);
  const openOrder = (order: Order) => {
    // const loadingProducts = order.products.map((p) => {
    //   return { ...p, _loading: true };
    // });
    // dispatch(setSelectedOrder({ ...order, products: loadingProducts }));
    // order.products.forEach((p) => {
    //   dispatch(getProductPrices({ trueProductId: p._id, productId: p.productRef, storeId: order.store._id }));
    // });
    dispatch(setSelectedOrder(order));
    setShowOrder(true);
  };

  const handleRejectOrder = () => {
    if (selectedOrder) {
      dispatch(declineOrder({ orderId: selectedOrder._id, callback: refreshOrders }));
    }
    setShowOrder(false);
  };

  const handleSubmitRFQ = () => {
    if (selectedOrder) {
      dispatch(
        acceptOrder({
          orderId: selectedOrder._id,
          productPrices: selectedOrder.products.map((p) => {
            return { productId: p._id, price: p.price ? p.price : 0 };
          }),
        }),
      );
    }
  };

  const handleDiscountAccept = (totalPrice: number) => {
    if (selectedOrder)
      dispatch(discountOrder({ orderId: selectedOrder._id, discount: totalPrice, callback: refreshOrders }));
  };

  const handlePartialAccept = (
    selectedItemsIds: string[],
    modifyProducts: { productId: string; quantity: number }[],
  ) => {
    if (selectedOrder)
      dispatch(
        partialAcceptOrder({
          orderId: selectedOrder._id,
          rejectedProducts: selectedItemsIds,
          modifiedProducts: modifyProducts,
          callback: refreshOrders,
        }),
      );
  };

  const handleDeliverOrder = () => {
    if (selectedOrder) dispatch(deliverOrder({ orderId: selectedOrder._id, callback: refreshOrders }));
  };

  const handleUpdateLocalOrder = (o: Order) => {
    dispatch(setSelectedOrder(o));
  };

  const getCellHeaderContent = (params: {
    text: string;
    onClick?: (obj: boolean | null) => void;
    extra?: boolean;
    state?: null | boolean;
  }) => {
    return (
      <Grid
        container
        wrap={'nowrap'}
        justify={'space-between'}
        className={params.extra ? classes.extraSpace : undefined}
      >
        <Grid item>
          <Typography style={typo(12, 'bold')}>{params.text}</Typography>
        </Grid>
        {params.onClick && (
          <Grid item className={classes.toggleSelect}>
            <AgoraToggleSort onClick={params.onClick} state={params.state} />
          </Grid>
        )}
      </Grid>
    );
  };

  const getBackgroundColor = (status: ORDER_STATUS) => {
    if (status === ORDER_STATUS.PENDING_RFQ) return fade('#EC9937', 0.12);
    else if (status === ORDER_STATUS.PENDING_ACCEPTANCE) return fade('#AA46DA', 0.12);
    else if (status === ORDER_STATUS.REJECTED || status === ORDER_STATUS.CANCELED) return fade('#FF9292', 0.18);
    else if (status === ORDER_STATUS.DELIVERED) return fade('#91EDC7', 0.26);
    else if (status === ORDER_STATUS.ACCEPTED) return fade('#367BF5', 0.15);
  };

  const getButton = (onClick: () => void) => {
    return (
      <Box className={classes.actionButton} onClick={onClick}>
        <Typography color={'secondary'} style={typo(12, 'semi')}>
          {t('show')}
        </Typography>
      </Box>
    );
  };

  const getColoredStatus = (status: ORDER_STATUS, autoAccept: boolean) => {
    if (status === ORDER_STATUS.PENDING_RFQ && !autoAccept)
      return (
        <Typography style={{ color: theme.palette.warning.main, ...typo(12, 'bold') }}>
          {t('ui-lib.pending-rfq')}
        </Typography>
      );
    else if (status === ORDER_STATUS.PENDING_RFQ && autoAccept)
      return (
        <Typography style={{ color: theme.palette.warning.main, ...typo(12, 'bold') }}>
          {t('ui-lib.pending')}
        </Typography>
      );
    else if (status === ORDER_STATUS.PENDING_ACCEPTANCE)
      return (
        <Typography style={{ color: '#AA46DA', ...typo(12, 'bold') }}>{t('ui-lib.pending-acceptance')}</Typography>
      );
    else if (status === ORDER_STATUS.REJECTED)
      return (
        <Typography style={{ color: theme.palette.error.main, ...typo(12, 'bold') }}>{t('status.rejected')}</Typography>
      );
    else if (status === ORDER_STATUS.CANCELED)
      return (
        <Typography style={{ color: theme.palette.error.main, ...typo(12, 'bold') }}>{t('status.canceled')}</Typography>
      );
    else if (status === ORDER_STATUS.DELIVERED)
      return (
        <Typography style={{ color: theme.palette.success.main, ...typo(12, 'bold') }}>
          {t('status.delivered')}
        </Typography>
      );
    else if (status === ORDER_STATUS.ACCEPTED)
      return (
        <Typography style={{ color: theme.palette.info.main, ...typo(12, 'bold') }}>{t('status.accepted')}</Typography>
      );
  };

  const getTableHead = () => {
    return (
      <TableHead style={{ background: '#F6F6F6' }}>
        <TableRow className={classes.row}>
          <TableCell>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
              {getCellHeaderContent({ text: t('order.name'), extra: true })}
            </Grid>
          </TableCell>
          {showSupplierName && (
            <TableCell>
              <Grid container wrap={'nowrap'} alignItems={'center'}>
                {getCellHeaderContent({ text: t('order.supplier') })}
              </Grid>
            </TableCell>
          )}
          <TableCell>
            {getCellHeaderContent({
              text: t('order.createdDate'),
              onClick: setCreatedDateSort,
              state: createdDateSort,
            })}
          </TableCell>
          <TableCell>
            {getCellHeaderContent({
              text: t('order.deliveryDate'),
              onClick: setDeliveryDateSort,
              state: deliveryDateSort,
            })}
          </TableCell>
          <TableCell>
            {getCellHeaderContent({
              text: t('order.situation'),
            })}
          </TableCell>
          <TableCell>
            {getCellHeaderContent({ text: t('order.code'), onClick: setCodeSort, state: codeSort })}
          </TableCell>
          <TableCell>{getCellHeaderContent({ text: t('order.basket') })}</TableCell>
          <TableCell>
            {getCellHeaderContent({ text: t('order.sumBasket'), onClick: setCostSort, state: costSort })}
          </TableCell>
          <TableCell>{getCellHeaderContent({ text: t('actions') })}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const getRow = (order: Order) => {
    const { orderNumber = '', createdAt = '', deliveryDate = '', store = '', supplier = '' } = order ? order : {};

    const image = order.store?.storeImage ? order.store.storeImage : '';

    return (
      <TableRow className={classes.row}>
        <TableCell padding={'none'}>
          <Grid container wrap={'nowrap'} alignItems="center" style={{ padding: 8 }}>
            <Grid item>
              {image ? (
                <img src={image} alt={'store-image'} className={classes.storeImg} />
              ) : (
                <img src={DefaultImage} alt={'store-image'} className={clsx(classes.storeImg, classes.defaultIcon)} />
              )}
            </Grid>
            <Grid item>
              <Typography style={typo(14, 'semi')}>{store ? (store.name ? store.name : 'N/A') : 'N/A'}</Typography>
            </Grid>
          </Grid>
        </TableCell>
        {showSupplierName && (
          <TableCell>
            <Typography style={{ ...typo(14, 'semi'), display: 'inline' }}>
              {supplier && supplier.companyDetails ? supplier.companyDetails.name : 'N/A'}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <Typography style={typo(12, 'bold')}>{moment(createdAt).format('DD/ MM /YYYY')}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{moment(deliveryDate).format('DD/ MM /YYYY')}</Typography>
        </TableCell>
        <TableCell style={{ background: getBackgroundColor(order.status) }}>
          {getColoredStatus(order.status, order.autoAccept)}
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>{orderNumber}</Typography>
        </TableCell>
        <TableCell>
          <Typography style={{ ...typo(12, 'bold'), textAlign: 'center' }}>
            {order.products.filter((product) => !product.rejected).length}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography style={typo(12, 'bold')}>€{' ' + getOrderTotalCost(order, pricelists)}</Typography>
        </TableCell>

        <TableCell>{getButton(() => openOrder(order))}</TableCell>
      </TableRow>
    );
  };

  const getTableBody = () => {
    return (
      <TableBody className={classes.body}>
        {!loading ? (
          orders.length === 0 ? (
            <TableRow>
              <TableCell padding={'none'} colSpan={7}>
                <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                  <Typography style={{ ...typo(16, 'regular'), color: '#999' }}>{t('msg.data')}</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            orders.map((order, index) => <React.Fragment key={index}>{getRow(order)}</React.Fragment>)
          )
        ) : (
          <TableRow>
            <TableCell padding={'none'} colSpan={7}>
              <Grid container style={{ padding: '16px 8px', textAlign: 'center' }} justify={'center'}>
                <CircularProgress style={{ width: 24, height: 24, color: '#999' }} />
              </Grid>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <>
      <Table>
        {getTableHead()}
        {getTableBody()}
      </Table>
      {selectedOrder && (
        <AgoraOrderSuperDrawer
          role={'supplier'}
          open={showOrder}
          units={units}
          pricelists={pricelists}
          onClose={() => setShowOrder(false)}
          selectedOrder={selectedOrder}
          setSelectedOrder={handleUpdateLocalOrder}
          handlePartialFunction={handlePartialAccept}
          handleDiscountFunction={handleDiscountAccept}
          handleRejectOrderFunction={handleRejectOrder}
          handleSubmitRFQ={handleSubmitRFQ}
          handleDeliverOrderFunction={handleDeliverOrder}
        />
      )}
    </>
  );
};

export default OrdersInnerTable;
