import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px  solid #E3E3E3',
    width: '100%',
    background: theme.palette.secondary.main,
  },
}));

type CustomTableBodyProps = unknown;

const CustomTableBody: React.FC<CustomTableBodyProps> = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
};

export default CustomTableBody;
