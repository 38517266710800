import React, { FC } from 'react';
import { Box, BoxProps, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ReactComponent as ChevronLeftIcon } from '../../assets/svgs/previous.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/svgs/next.svg';
import { typo } from '../../utils/typo';

const useStyles = makeStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.87)',
    borderRadius: '8px',
    padding: 6,
  },
  button: {
    padding: 1,
    minWidth: 0,
    minHeight: 0,
    background: 'none',
  },
  buttonDisabled: {
    background: 'none',
    padding: 1,
    minWidth: 0,
    minHeight: 0,
    opacity: 0.3,
  },

  selectButton: {
    color: 'rgba(0, 0, 0, 0.87)',
    borderBottom: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    fontSize: 11,
    cursor: 'pointer',
  },
});

interface PaginationFilterProps {
  customTheme?: Theme;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  allCount: number;
}

const PaginationFilter: FC<PaginationFilterProps & BoxProps> = ({ page, setPage, pageSize, allCount, ...props }) => {
  const classes = useStyles();

  const onClickRight = () => {
    if ((page + 1) * pageSize < allCount) {
      setPage(page + 1);
    }
  };

  const onClickLeft = () => {
    setPage(page - 1);
  };

  const rightClickDisabled = () => {
    return !((page + 1) * pageSize < allCount);
  };

  const prepareSelectedValue = () => {
    let start = !page ? 1 : page * pageSize;
    if (allCount === 0) start = 0;
    const end = pageSize + page * pageSize > allCount ? allCount : pageSize + page * pageSize;
    return (
      <Typography
        style={{ ...typo(12, 'regular'), whiteSpace: 'nowrap' }}
        component={'span'}
        className={classes.selectButton}
      >
        <strong>
          {start}-{end}
        </strong>
      </Typography>
    );
  };

  return (
    <Box className={classes.root} {...props}>
      <Grid container wrap={'nowrap'}>
        <Grid item style={{ marginRight: 6 }} container wrap={'nowrap'}>
          <Grid item>{prepareSelectedValue()}</Grid>
          <Grid item>
            <Typography style={{ ...typo(12, 'regular'), whiteSpace: 'nowrap' }} component={'span'}>
              <span style={{ marginLeft: 3 }}>{' of '}</span> <strong>{allCount}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container wrap={'nowrap'}>
          <Button
            disabled={!page}
            style={{ marginRight: 6 }}
            onClick={onClickLeft}
            className={!page ? classes.buttonDisabled : classes.button}
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            disabled={rightClickDisabled()}
            className={rightClickDisabled() ? classes.buttonDisabled : classes.button}
            onClick={onClickRight}
          >
            <ChevronRightIcon />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaginationFilter;
