import { Box, Container, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Buyer } from 'types-library';
import { AgoraButton, AgoraTextField } from 'ui-components';
import { ReactComponent as CloseIcon } from '../../assets/svgs/x.svg';
import useTranslations from '../../hooks/useTranslations';
import { putEditBuyerThunk } from '../../redux/slices/buyerSlice';
import { typo } from '../../utils/typo';
import BoxContainer from '../common/BoxContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1F1F1',
    width: '100%',
    maxWidth: 1200,
  },
  appBar: {
    width: '100%',
    maxWidth: 1195,
    paddingTop: 20,
    paddingRight: 16,
    paddingBottom: 20,
    zIndex: 900,
    '&.MuiAppBar-root': {
      background: '#F1F1F1',
      '& .MuiPaper-root': {
        boxShadow: '0px 1px 1px rgb(0, 0, 0, 0.25 )',
      },
    },
    '&.MuiPaper-elevation4': {
      boxShadow: 'none',
    },
  },
  buttonClose: {
    padding: 0,
  },
  container: {
    width: '100%',
    '&.MuiContainer-root': {
      paddingRight: 16,
      marginLeft: 80,
      paddingTop: 60,
    },
  },
  title: {
    ...typo(32, 'semi'),
    color: theme.palette.primary.main,
  },
}));

type AddBuyerModalProps = {
  closeDrawer: () => void;
  editBuyer: Buyer;
};

const EditBuyerModal: FC<AddBuyerModalProps> = ({ closeDrawer, editBuyer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslations();

  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    if (editBuyer && editBuyer.user) {
      setFullName(editBuyer.name);
      setEmail(editBuyer.user.email);
    }
  }, [editBuyer]);

  const putBuyer = () => {
    if (editBuyer && editBuyer._id) {
      let params: Buyer = editBuyer;
      params = { ...params, name: fullName };
      dispatch(putEditBuyerThunk({ id: editBuyer._id, buyer: params }));
      closeDrawer();
    }
  };

  const appBar = () => {
    return (
      <Grid container alignItems={'center'} justify={'flex-start'} className={classes.appBar}>
        <Grid container alignItems={'center'} justify={'flex-start'}>
          <Grid container item xs={1} alignItems={'center'} justify={'center'}>
            <IconButton className={classes.buttonClose} onClick={() => closeDrawer()}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container item xs={6} justify={'flex-start'} alignItems={'center'}>
            <Typography className={classes.title}>{t('edit.buyer')}</Typography>
          </Grid>
          <Grid container item xs={5} justify={'flex-end'} alignItems={'center'} spacing={2}>
            <Grid item xs={4}>
              <AgoraButton
                title={t('cancel')}
                padding={14}
                variant={'outlined'}
                handleClick={closeDrawer}
                background={'#FFFFFF'}
                color={''}
              />
            </Grid>
            <Grid item xs={8} md={7} lg={6}>
              <AgoraButton title={t('save')} padding={14} handleClick={() => putBuyer()} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid container item xs={12} style={{ marginTop: 12 }}>
          {appBar()}
        </Grid>
        <Container maxWidth={'md'} className={classes.container}>
          <Grid container item justify={'space-between'} spacing={2} xs={12}>
            <Grid item xs={6}>
              <BoxContainer title={t('data.companyInfo')}>
                <Grid container alignItems={'center'}>
                  <Grid container item alignItems={'center'} justify={'center'} xs={12}>
                    <Grid item xs={12}>
                      <AgoraTextField
                        label={t('nameSur')}
                        radius={'8px 8px 0px 0px'}
                        text
                        value={fullName}
                        setValue={setFullName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AgoraTextField
                        text
                        label={'Email'}
                        radius={'0px 0px 8px 8px'}
                        value={email}
                        setValue={setEmail}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </BoxContainer>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
};

export default EditBuyerModal;
