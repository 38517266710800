import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Buyer, Order, Product } from 'types-library';
import {
  deleteBuyerRequest,
  getAllBuyersRequest,
  getBuyerRequest,
  postBuyerRequest,
  putBuyerRequest,
} from '../../lib/api/http/requests/buyer';
import { getProductsRequest } from '../../lib/api/http/requests/products';
import { ICreateBuyerBody } from '../../lib/api/http/types/ICreateBuyerBody';
import { Pagination } from '../../lib/api/http/types/Pagination';
import { BuyerSliceType } from '../../models/slices/BuyerSliceType';
import { openToast } from './appSlice';

export const postBuyerThunk = createAsyncThunk('buyers/postBuyers', async (params: ICreateBuyerBody, thunkAPI) => {
  try {
    const res = await postBuyerRequest({ ...params });
    if (res) {
      thunkAPI.dispatch(openToast({ text: 'msg.verification', success: true }));
    }
  } catch (error: any) {
    if (error && error.response && error.response.data && error.response.data.details) {
      const details = error.response.data.details;
      if (details.includes('User already exists')) {
        thunkAPI.dispatch(openToast({ text: 'msg.user', success: false }));
      }
    } else {
      thunkAPI.dispatch(openToast({ text: 'error.dataSuppliers', success: false }));
    }
    throw error;
  }
});

export const getAllBuyersThunk = createAsyncThunk('buyers/getBuyers', async (params: Pagination, thunkAPI) => {
  try {
    return await getAllBuyersRequest(params);
  } catch (error) {
    thunkAPI.dispatch(openToast({ text: 'error.dataBuyers' }));
    throw error;
  }
});

export const deleteBuyerThunk = createAsyncThunk('buyers/deleteBuyer', async (params: string, thunkAPI) => {
  try {
    thunkAPI.dispatch(setBuyerId(params));
    return await deleteBuyerRequest(params);
  } catch (error) {
    thunkAPI.dispatch(openToast({ text: 'error.deleteBuyer' }));
    throw error;
  }
});

export const getEditBuyerThunk = createAsyncThunk('buyers/editBuyer', async (params: string, thunkAPI) => {
  try {
    return await getBuyerRequest(params);
  } catch (error) {
    thunkAPI.dispatch(openToast({ text: 'error.editBuyer' }));
    throw error;
  }
});

export const putEditBuyerThunk = createAsyncThunk(
  'buyers/putBuyer',
  async (params: { id: string; buyer: Buyer }, thunkAPI) => {
    try {
      return await putBuyerRequest(params.id, params.buyer);
    } catch (error) {
      thunkAPI.dispatch(openToast({ text: 'error.putBuyer' }));
      throw error;
    }
  },
);

export const getProducts = createAsyncThunk(
  'buyers/getProducts',
  async (params: { storeId: string; searchText?: string; skip: number; limit: number; sort: string }, thunkAPI) => {
    try {
      const { documents, documentsCount } = await getProductsRequest(params);
      return { documents, documentsCount };
    } catch (error: any) {
      if (error.response.data?.message) {
        const msg = error.response.data.message;
        if (msg === 'Buyer has no stores!') {
          thunkAPI.dispatch(openToast({ text: 'error.buyer-no-stores' }));
        } else if (msg === 'Store not found!') {
          thunkAPI.dispatch(openToast({ text: 'error.buyer-not-found' }));
        } else if (msg === 'Store has no active suppliers yet! So no products for you!') {
          thunkAPI.dispatch(openToast({ text: 'error.buyer-no-active-suppliers' }));
        } else {
          thunkAPI.dispatch(openToast({ text: 'error.common' }));
        }
      } else {
        thunkAPI.dispatch(openToast({ text: 'error.common' }));
      }
      throw error;
    }
  },
);

const initialState: BuyerSliceType = {
  data: {
    buyers: [],
    editBuyer: null,
    products: [],
    productsCount: 0,
    productsLoading: false,
    suppliers: [],
    orders: [],
    pagination: {
      buyers: 0,
      stores: 0,
      suppliers: 0,
      orders: 0,
    },
    buyerId: '',
    storeId: '',
  },
  meta: {
    loading: false,
    error: '',
    success: '',
  },
};

const buyerSlice = createSlice({
  name: 'buyer',
  initialState: initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.meta.loading = action.payload;
    },
    setOrders(state, action: PayloadAction<{ orders: Order[]; documentsCount?: number }>) {
      state.data.orders = action.payload.orders;
      state.data.pagination.orders = action.payload.documentsCount ? action.payload.documentsCount : 0;
    },
    setBuyerId(state, action: PayloadAction<string>) {
      state.data.buyerId = action.payload;
    },
    setInitialState(state) {
      state.data.editBuyer = initialState.data.editBuyer;
    },
    setProducts(state, action: PayloadAction<{ products: Product[]; documentsCount?: number }>) {
      state.data.products = action.payload.products;
      state.data.productsCount = action.payload.documentsCount ? action.payload.documentsCount : 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBuyersThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(getAllBuyersThunk.fulfilled, (state, action) => {
      state.data.buyers = action.payload.buyers;
      state.data.pagination.buyers = action.payload.buyersCount;
      state.meta.loading = false;
    });
    builder.addCase(getAllBuyersThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(deleteBuyerThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(deleteBuyerThunk.fulfilled, (state) => {
      const newBuyers = [...state.data.buyers];
      const deleteId = state.data.buyerId;
      newBuyers.splice(
        newBuyers.findIndex((buyer) => buyer._id === deleteId),
        1,
      );
      state.data.buyers = newBuyers;
      state.meta.loading = false;
    });
    builder.addCase(deleteBuyerThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(getEditBuyerThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(getEditBuyerThunk.fulfilled, (state, action) => {
      state.data.editBuyer = action.payload;
      state.meta.loading = false;
    });
    builder.addCase(getEditBuyerThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(putEditBuyerThunk.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = '';
    });
    builder.addCase(putEditBuyerThunk.fulfilled, (state, action) => {
      const newBuyer = action.payload;
      const buyers = [...state.data.buyers];
      const found = buyers.findIndex((buyer) => buyer._id === newBuyer?._id);
      if (found != -1) {
        buyers[found].name = newBuyer.name;
      }
      state.data.buyers = buyers;
      initialState.data.editBuyer;
      state.meta.loading = false;
    });
    builder.addCase(putEditBuyerThunk.rejected, (state) => {
      state.meta.error = 'Error';
      state.meta.loading = false;
    });
    builder.addCase(getProducts.pending, (state) => {
      state.data.productsLoading = true;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.data.products = action.payload.documents;
      state.data.productsCount = action.payload.documentsCount;
      state.data.productsLoading = false;
    });
    builder.addCase(getProducts.rejected, (state) => {
      state.data.productsLoading = false;
    });
  },
});

export const { setOrders, setLoading, setBuyerId, setProducts, setInitialState } = buyerSlice.actions;
export default buyerSlice.reducer;
