import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px  solid #E3E3E3',
    overflow: 'auto',
    borderRadius: 8,
  },
}));

type CustomTableContainerProps = unknown;

const CustomTableContainer: React.FC<CustomTableContainerProps> = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
};

export default CustomTableContainer;
