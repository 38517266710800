import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      display: 'inline-block',
      position: 'relative',
      borderRadius: 50,
      cursor: 'pointer',
      background: theme.palette.primary.light,
      border: '1px solid ' + '#9e9e9e',
      padding: 2,
    },
    rootSelected: {
      width: 60,
      display: 'inline-block',
      position: 'relative',
      borderRadius: 50,
      cursor: 'pointer',
      background: '#e0e0e0',
      border: '1px solid ' + '#9e9e9e',
      padding: 2,
    },
    gridIcons: {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: theme.spacing(0.5, 1.8),
      height: '100%',
    },
    icon: {
      '& svg': {
        '& path': {
          transition: 'all 0.2s ease-out',
          fill: theme.palette.primary.light,
          stroke: theme.palette.primary.light,
          strokeWidth: '0.5px',
        },
      },
    },
    iconSelected: {
      '& svg': {
        '& path': {
          fill: theme.palette.primary.main,
          stroke: theme.palette.primary.main,
          strokeWidth: '0.5px',
        },
      },
    },
    span: {
      position: 'relative',
      left: 0,
      transition: 'all 0.2s ease-out',
      borderRadius: 40,
      width: 30,
      height: 25,
      background: '#424242',
    },

    toggleRight: {
      background: theme.palette.info.main,
      left: 'calc(100% - 30px)',
    },
  }),
);

type SwitchProps = {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
};

const Switch: FC<SwitchProps> = ({ toggle, setToggle }) => {
  const classes = useStyles();

  return (
    <div className={toggle ? classes.rootSelected : classes.root} onClick={() => setToggle(!toggle)}>
      <div className={`${classes.span} ${toggle ? classes.span : classes.toggleRight}`} />
      <Grid container wrap={'nowrap'} alignItems={'center'} justify={'space-between'} className={classes.gridIcons}>
        <div className={`${classes.icon} ${toggle ? classes.iconSelected : classes.icon}`} />
      </Grid>
    </div>
  );
};

export default Switch;
