import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Supplier } from 'types-library';
import { AgoraButton, AgoraPaginationFilter, AgoraSearchInput } from 'ui-components';
import { ReactComponent as Info } from '../../assets/svgs/info.svg';
import DrawerCart from '../../components/common/DrawerCart';
import AddSupplierModal from '../../components/modals/AddSupplierModal';
import EditSupplierModal from '../../components/modals/EditSupplierModal';
import CustomTableBody from '../../components/table/CustomTableBody';
import CustomTableContainer from '../../components/table/CustomTableContainer';
import CustomTableHead from '../../components/table/CustomTableHead';
import InnerSupplierTable from '../../components/table/InnerSupplierTable';
import TableTabs from '../../components/table/TableTabs';
import { useDebounce } from '../../hooks/useDebounce';
import useTranslations from '../../hooks/useTranslations';
import { setLayout } from '../../redux/slices/layoutSlice';
import { getSuppliers, setSelectedSupplier, updateSupplier } from '../../redux/slices/supplierSlice';
import { RootState } from '../../redux/store';
import { typo } from '../../utils/typo';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 67,
    paddingBottom: 93,
  },
  typography: {
    ...typo(14, 'regular'),
    color: '#9E9898',
  },
  gridInfo: {
    marginTop: 13,
  },
  spaceInfo: {
    marginRight: 4,
  },
  gridTable: {
    paddingTop: theme.spacing(4.5),
    paddingBottom: theme.spacing(6),
  },
}));

const RESULTS_PER_PAGE = 10;

type SuppliersPageProps = unknown;

const SuppliersPage: React.FC<SuppliersPageProps> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslations();

  const maxSuppliersCount = useSelector((state: RootState) => state.supplier.pagination.suppliers);
  const suppliers = useSelector((state: RootState) => state.supplier.suppliers);
  const loading = useSelector((state: RootState) => state.supplier.loading);
  const selectedSupplier = useSelector((state: RootState) => state.supplier.selectedSupplier);

  const [tab, setTab] = useState<number>(0);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState<boolean>(false);
  const [showEditSupplierModal, setShowEditSupplierModal] = useState<boolean>(false);
  const [refetchSuppliers, setRefetchSupplier] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<string>('');

  const tabs = [t('active.suppliers')];

  const debouncedSearchText = useDebounce(searchInput, 500);

  useEffect(() => {
    const sort = '';
    dispatch(
      getSuppliers({
        params: {
          skip: page * RESULTS_PER_PAGE,
          limit: RESULTS_PER_PAGE,
          sort,
          search: debouncedSearchText,
        },
      }),
    );
  }, [dispatch, page, tab, debouncedSearchText, refetchSuppliers]);

  // Layout Config Effect
  useEffect(() => {
    dispatch(setLayout({ header: true, mainMenu: true, footer: true }));
  }, [dispatch]);

  const handleEditSupplier = (newSupplier: Supplier) => {
    dispatch(updateSupplier({ supplier: newSupplier, callback: () => setRefetchSupplier(refetchSuppliers + 1) }));
  };

  const onClickEditClient = (currentSupplier: Supplier) => {
    dispatch(setSelectedSupplier(currentSupplier));
    setShowEditSupplierModal(true);
  };

  return (
    <Container maxWidth={'lg'}>
      <Grid container alignItems={'center'} justify={'center'} className={classes.root}>
        <Grid item justify={'space-between'} xs={12} container wrap={'nowrap'} alignItems={'center'}>
          <Grid item>
            <Typography style={typo(32, 'bold')}>{t('suppliers')}</Typography>
          </Grid>
          <Grid item>
            <AgoraButton title={t('create')} padding={10} handleClick={() => setShowAddSupplierModal(true)} />
          </Grid>
        </Grid>
        <Grid container item justify={'flex-start'} alignItems={'center'} xs={12} className={classes.gridInfo}>
          <Grid item className={classes.spaceInfo}>
            <Info />
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.typography}>{t('viewEdit')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridTable}>
          <CustomTableContainer>
            <CustomTableHead>
              <Grid container justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
                <Grid item>
                  <TableTabs tabs={tabs} selectedTab={tab} setSelectedTab={setTab} />
                </Grid>
                <Grid item container style={{ flex: 0 }} wrap={'nowrap'} justify={'flex-end'}>
                  <Grid item>
                    <AgoraSearchInput
                      border={'1px solid #E3E3E3'}
                      placeholder={t('search.supplier')}
                      changeSearch={setSearchInput}
                      scroll={false}
                    />
                  </Grid>
                  <Grid item>
                    <AgoraPaginationFilter
                      page={page}
                      setPage={setPage}
                      pageSize={RESULTS_PER_PAGE}
                      allCount={maxSuppliersCount}
                      style={{ marginLeft: 16 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CustomTableHead>
            <CustomTableBody>
              <InnerSupplierTable clients={suppliers} viewClient editClient={onClickEditClient} loading={loading} />
            </CustomTableBody>
          </CustomTableContainer>
        </Grid>
      </Grid>

      <DrawerCart open={showAddSupplierModal} closeDrawer={() => setShowAddSupplierModal(false)}>
        <AddSupplierModal closeDrawer={() => setShowAddSupplierModal(false)} />
      </DrawerCart>
      {selectedSupplier && (
        <DrawerCart open={showEditSupplierModal} closeDrawer={() => setShowEditSupplierModal(false)}>
          <EditSupplierModal
            closeDrawer={() => setShowEditSupplierModal(false)}
            selectedSupplier={selectedSupplier}
            handleEditSupplier={handleEditSupplier}
          />
        </DrawerCart>
      )}
    </Container>
  );
};

export default SuppliersPage;
